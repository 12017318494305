import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";
import { changeWithdrawStatus } from "../../actions/withdrawStatus";
import { WithdrawStatusView } from "./View";
import { WITHDRAW_STATUS } from "../../constants/static";
import { getWithdrawStatus } from "../../api";

export const WithdrawStatus = () => {
  const dispatch = useDispatch();

  const {
    data,
    isDataLoaded,
    isDataLoading,
    isLoading,
    isError,
    errorMessage,
  } = useSelector(
    ({
      withdrawInfo: { data: withdrawInfo },
      withdrawStatus: { request: withdrawStatus },
    }) => ({
      data: getData(withdrawInfo),
      isDataLoaded: isRequestSuccess(withdrawInfo),
      isDataLoading: isRequestPending(withdrawInfo),
      isLoading: isRequestPending(withdrawStatus),
      isError: isRequestError(withdrawStatus),
      errorMessage: getErrorMessage(withdrawStatus),
    })
  );

  const { withdrawId } = useParams();

  const handleApprove = () => {
    dispatch(
      changeWithdrawStatus({
        withdrawId,
        status: WITHDRAW_STATUS.APPROVED,
      })
    );
  };

  const handleReject = () => {
    dispatch(
      changeWithdrawStatus({
        withdrawId,
        status: WITHDRAW_STATUS.REJECTED,
      })
    );
  };

  if (data.status !== getWithdrawStatus(WITHDRAW_STATUS.PENDING)) {
    return null;
  }

  const getLoading = () => {
    return !isDataLoaded || isDataLoading || isLoading;
  };

  return (
    <WithdrawStatusView
      dataLoaded={isDataLoaded}
      loading={getLoading()}
      handleApprove={handleApprove}
      handleReject={handleReject}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
