import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getWithdrawInfo,
  updateWithdrawInfo,
} from "../../actions/withdrawInfo";
import { WithdrawInfoView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const WithdrawInfo = () => {
  const { data, isLoading, isLoaded, isError, errorMessage, isUpdating } =
    useSelector(({ withdrawInfo: { data: withdrawInfo } }) => ({
      data: getData(withdrawInfo),
      isLoading: isRequestPending(withdrawInfo),
      isLoaded: isRequestSuccess(withdrawInfo),
      isError: isRequestError(withdrawInfo),
      errorMessage: getErrorMessage(withdrawInfo),
      isUpdating: withdrawInfo.updating,
    }));

  const { withdrawId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded && withdrawId === data._id) {
      dispatch(updateWithdrawInfo({ withdrawId }));
    } else {
      dispatch(getWithdrawInfo({ withdrawId }));
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <WithdrawInfoView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      isUpdating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
