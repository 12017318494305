import { axios } from "../utils/request";
import { API } from "../constants/api";
import { WITHDRAW_INFO } from "./index";
import { performWithdrawInfo } from "../api/withdraw";

const setFail = (message) => ({
  type: WITHDRAW_INFO.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: WITHDRAW_INFO.LOAD_SUCCESS,
  data: performWithdrawInfo(data),
});

const setLoading = () => ({
  type: WITHDRAW_INFO.LOAD_PENDING,
});

const setUpdating = () => ({
  type: WITHDRAW_INFO.UPDATE_PENDING,
});

const setUpdated = (data) => ({
  type: WITHDRAW_INFO.UPDATE_SUCCESS,
  data: performWithdrawInfo(data),
});

export const getWithdrawInfo = ({ withdrawId }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.WITHDRAW_INFO({ withdrawId }));
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateWithdrawInfo = ({ withdrawId }) => {
  return async (dispatch) => {
    dispatch(setUpdating());

    try {
      const response = await axios.get(API.WITHDRAW_INFO({ withdrawId }));
      dispatch(setUpdated(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
