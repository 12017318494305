import { axios } from "../utils/request";
import { API } from "../constants/api";

export const USER = {
  LOADING: "USER.LOADING",
  SUCCESS: "USER.SUCCESS",
  ERROR: "USER.ERROR",
};

const setLoading = () => ({
  type: USER.LOADING,
});

const setSuccess = (data) => ({
  type: USER.SUCCESS,
  data,
});

const setError = (message) => ({
  type: USER.ERROR,
  message,
});

export const getUser = () => {
  return (dispatch) => {
    dispatch(setLoading());

    return (
      axios
        .get(API.USER_DATA)
        .then(({ data }) => {
          dispatch(setSuccess(data));
        })
        // .catch(({ response: { data } }) => {
        .catch((e) => {
          console.log(e);
          // dispatch(setError(data.message));
        })
    );
  };
};
