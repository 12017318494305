import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getStatisticsBotsBalance,
  updateStatisticsBotsBalance,
} from "../../actions/statisticsBotsBalance";
import { StatisticsBotsBalanceView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const StatisticsBotsBalance = () => {
  const { data, isLoading, isLoaded, isError, errorMessage } = useSelector(
    ({ statisticsBotsBalance: { data: statisticsBotsBalance } }) => ({
      data: getData(statisticsBotsBalance),
      isLoading: isRequestPending(statisticsBotsBalance),
      isLoaded: isRequestSuccess(statisticsBotsBalance),
      isError: isRequestError(statisticsBotsBalance),
      errorMessage: getErrorMessage(statisticsBotsBalance),
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(updateStatisticsBotsBalance());
    } else {
      dispatch(getStatisticsBotsBalance());
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <StatisticsBotsBalanceView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
