import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAGINATION_OFFSET } from "../../constants/routes";
import {
  getWithdrawList,
  updateWithdrawList,
  resetStore,
} from "../../actions/withdrawList";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isUpdatePending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

import { WithdrawView } from "./View";
import { WITHDRAW_STATUS, WITHDRAW_TYPE } from "../../constants/static";

export const WithdrawList = () => {
  const {
    isLoading,
    isLoaded,
    isError,
    errorMessage,
    withdrawsData,
    isUpdating,
  } = useSelector(({ withdrawList: { data } }) => ({
    isLoading: isRequestPending(data),
    isLoaded: isRequestSuccess(data),
    isUpdating: isUpdatePending(data),
    isError: isRequestError(data),
    errorMessage: getErrorMessage(data),
    withdrawsData: getData(data),
  }));

  const [currentStatus, setCurrentStatus] = useState(WITHDRAW_STATUS.PENDING);
  const [currentType, setCurrentType] = useState(WITHDRAW_TYPE.DEFAULT);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        updateWithdrawList({
          skip: 0,
          take: PAGINATION_OFFSET.WITHDRAW_LIST,
          status: currentStatus,
          type: currentType,
        })
      );
    } else {
      dispatch(
        getWithdrawList({
          skip: 0,
          take: PAGINATION_OFFSET.WITHDRAW_LIST,
          status: currentStatus,
          type: currentType,
        })
      );
    }

    // return () => {
    //   dispatch(resetStore());
    // };
  }, [currentStatus, currentType]);

  const paginationAction = () => {
    dispatch(
      updateWithdrawList({
        skip: withdrawsData.pagination.skip + PAGINATION_OFFSET.WITHDRAW_LIST,
        take: PAGINATION_OFFSET.WITHDRAW_LIST,
        status: currentStatus,
        type: currentType,
      })
    );
  };

  const handleChangeCurrentStatus = (e, status) => {
    setCurrentStatus(status);
  };

  const handleChangeCurrentType = (e, type) => {
    setCurrentType(type);
  };

  const loading = !isLoaded || isLoading;

  return (
    <WithdrawView
      loading={loading}
      loaded={isLoaded}
      updating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
      withdraws={withdrawsData}
      paginationAction={paginationAction}
      currentStatus={currentStatus}
      handleChangeCurrentStatus={handleChangeCurrentStatus}
      currentType={currentType}
      handleChangeCurrentType={handleChangeCurrentType}
    />
  );
};
