import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAGINATION_OFFSET } from "../../constants/routes";
import {
  getPartnerApplicationList,
  updatePartnerApplicationList,
  resetStore,
} from "../../actions/partnerApplicationList";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isUpdatePending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

import { PartnerApplicationView } from "./View";
import { PARTNER_APPLICATION_STATUS } from "../../constants/static";

export const PartnerApplicationList = () => {
  const {
    isLoading,
    isLoaded,
    isError,
    errorMessage,
    applicationsData,
    isUpdating,
  } = useSelector(({ partnerApplicationList: { data } }) => ({
    isLoading: isRequestPending(data),
    isLoaded: isRequestSuccess(data),
    isUpdating: isUpdatePending(data),
    isError: isRequestError(data),
    errorMessage: getErrorMessage(data),
    applicationsData: getData(data),
  }));

  const [currentStatusType, setCurrentStatusType] = useState(
    PARTNER_APPLICATION_STATUS.PENDING
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        updatePartnerApplicationList({
          skip: 0,
          take: PAGINATION_OFFSET.PARTNER_APPLICATION_LIST,
          status: currentStatusType,
        })
      );
    } else {
      dispatch(
        getPartnerApplicationList({
          skip: 0,
          take: PAGINATION_OFFSET.PARTNER_APPLICATION_LIST,
          status: currentStatusType,
        })
      );
    }

    // return () => {
    //   dispatch(resetStore());
    // };
  }, [currentStatusType]);

  const paginationAction = () => {
    dispatch(
      updatePartnerApplicationList({
        skip:
          applicationsData.pagination.skip +
          PAGINATION_OFFSET.PARTNER_APPLICATION_LIST,
        take: PAGINATION_OFFSET.PARTNER_APPLICATION_LIST,
        status: currentStatusType,
      })
    );
  };

  const loading = !isLoaded || isLoading;

  const handleChangeCurrentStatusType = (e, status) => {
    setCurrentStatusType(status);
  };

  return (
    <PartnerApplicationView
      loading={loading}
      loaded={isLoaded}
      updating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
      applications={applicationsData}
      paginationAction={paginationAction}
      currentStatusType={currentStatusType}
      handleChangeCurrentStatusType={handleChangeCurrentStatusType}
    />
  );
};
