import lang from "i18next";
import {
  USER_ROLE,
  WITHDRAW_STATUS,
  PARTNER_APPLICATION_STATUS,
  WITHDRAW_TYPE,
} from "../constants/static";

export const getDateTime = (raw) => new Date(raw).toLocaleString();
export const getDate = (raw) => new Date(raw).toLocaleDateString();

export const getUserRole = (role) => {
  const type = Object.values(USER_ROLE).find((id) => id === role);

  return lang.t(`STATIC.USER_ROLE_TYPE.${type}`);
};

export const getWithdrawStatus = (status) => {
  const type = Object.values(WITHDRAW_STATUS).find((item) => item === status);

  return lang.t(`STATIC.WITHDRAW_STATUS_TYPE.${type}`);
};

export const getWithdrawType = (status) => {
  const type = Object.values(WITHDRAW_TYPE).find((item) => item === status);

  return lang.t(`STATIC.WITHDRAW_TYPE.${type}`);
};

export const getPartnerApplicationStatus = (status) => {
  const type = Object.values(PARTNER_APPLICATION_STATUS).find(
    (item) => item === status
  );

  return lang.t(`STATIC.PARTNER_APPLICATION_STATUS_TYPE.${type}`);
};

export const performWithPagination =
  (fn) =>
  (raw, ...props) => {
    const { skip = 0, take = 0, amount = 0, list, ...rawElse } = raw;

    const pagination = {
      skip: Number(skip),
      take: Number(take),
      amountItems: Number(list.length),
      amountPages: Math.ceil(amount / take),
      currentPage: Math.ceil(skip / take),
    };

    const performedList = fn(list, ...props);

    return {
      list: performedList,
      pagination,
      ...rawElse,
    };
  };

export const performFile = (file) => {
  if (!file) {
    return null;
  }

  return {
    path: `${process.env.STATIC}/static/${file.type.toLowerCase()}/${
      file.path
    }`,
    id: file.id,
  };
};

export const performFileList = (fileList) => {
  if (!fileList) {
    return null;
  }

  return fileList.map((file) => performFile(file));
};
