import React from "react";
import styled from "styled-components";

import MuiSearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import { TextField } from ".";

export const SearchField = ({ ...props }) => {
  return (
    <TextField
      {...props}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

const SearchIcon = styled(MuiSearchIcon)`
  color: rgba(0, 0, 0, 0.54);
`;
