import { PARTNER_APPLICATION_INFO } from "../actions";
import {
  initRequestWithDataState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
  setUpdatePending,
  setUpdateSuccess,
  editData,
} from "../utils/store";

const initialState = {
  data: initRequestWithDataState(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_APPLICATION_INFO.LOAD_SUCCESS:
      return {
        ...state,
        data: setRequestSuccess(state.data, action.data),
      };
    case PARTNER_APPLICATION_INFO.LOAD_PENDING:
      return {
        ...state,
        data: setRequestPending(state.data),
      };
    case PARTNER_APPLICATION_INFO.LOAD_FAIL:
      return {
        ...state,
        data: setRequestError(state.data, action.message),
      };
    case PARTNER_APPLICATION_INFO.UPDATE_PENDING:
      return {
        ...state,
        data: setUpdatePending(state.data),
      };

    case PARTNER_APPLICATION_INFO.UPDATE_SUCCESS:
      return {
        ...state,
        data: setUpdateSuccess(state.data, action.data),
      };
    case PARTNER_APPLICATION_INFO.SET_DATA:
      return {
        ...state,
        data: editData(state.data, (data) => ({ ...data, ...action.data })),
      };
    case PARTNER_APPLICATION_INFO.SET_REFERRER:
      return {
        ...state,
        data: setUpdateSuccess(state.data, {
          ...state.data.data,
          referrer: action.data,
        }),
      };
    default:
      return state;
  }
};
