import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Box } from "../../components/cards";
import { Tabs, Tab as TabComponent } from "../../components/tabs";
import { Text } from "../../components";

export const UserMenuView = ({ tabs, activeTab, handleClickTab }) => (
  <Box variant="outlined">
    <Tabs value={activeTab} onChange={handleClickTab} orientation="vertical">
      {tabs.map(({ id, tid }) => (
        <Tab
          key={id}
          active={id === activeTab}
          component="a"
          label={<Text tid={tid} />}
          onClick={(e) => e.preventDefault()}
          value={id}
        />
      ))}
    </Tabs>
  </Box>
);

UserMenuView.propTypes = {
  handleClickTab: PropTypes.func,
  activeTab: PropTypes.string,
  tabs: PropTypes.array,
};

const Tab = styled(TabComponent)`
  .MuiTab-wrapper {
    width: auto !important;
    margin-right: auto !important;
  }
`;
