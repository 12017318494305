import React from "react";
import PropTypes from "prop-types";

import { Loader, AlertSection } from "../../components";
import { Box } from "../../components/cards";
import { SkeletonDataList } from "../../components/skeletons";
import { StaffList } from "./List";
import { ListActionTitle } from "../../components/titles";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <SkeletonDataList size="big" />
  </React.Fragment>
);

export const StaffListView = ({
  loading,
  loaded,
  error,
  errorMessage,
  staff,
  paginationAction,
  updating,
}) => {
  const isEmpty = loaded && staff.list.length === 0;

  return (
    <div>
      <Box variant="outlined">
        <ListActionTitle divider={!isEmpty} tid="USER.STAFF_LIST.TITLE" />
        {loading && <LoaderComponent />}
        {updating && <Loader />}
        {loaded && staff.list.length !== 0 && (
          <StaffList
            paginationAction={paginationAction}
            pagination={staff.pagination}
            updating={updating}
            list={staff.list}
          />
        )}
      </Box>
      <AlertSection
        info={isEmpty}
        error={error}
        errorMessage={errorMessage}
        infoMessage="USER.STAFF_LIST.EMPTY_LIST"
      />
    </div>
  );
};

StaffListView.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  staff: PropTypes.object,
  updating: PropTypes.bool,
  paginationAction: PropTypes.func,
};
