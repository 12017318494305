import React from "react";
import PropTypes from "prop-types";

import { Box } from "../../components/cards";
import { IndentLayout } from "../../components/layouts";
import { Loader, AlertSection } from "../../components";
import { SkeletonList } from "../../components/skeletons";
import { ListTitle } from "../../components/titles";
import { UserStatisticsInfo } from "./UserInfo";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <SkeletonList />
  </React.Fragment>
);

export const UserStatisticsInfoView = ({
  loading,
  loaded,
  error,
  errorMessage,
  data,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Box variant="outlined">
        <IndentLayout>
          <ListTitle tid="USER.USER_STATISTICS_INFO.TITLE" />
          {loading && <LoaderComponent />}
          {loaded && <UserStatisticsInfo {...data} {...rest} />}
          <AlertSection error={error} errorMessage={errorMessage} />
        </IndentLayout>
      </Box>
    </React.Fragment>
  );
};

UserStatisticsInfoView.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  data: PropTypes.object,
};
