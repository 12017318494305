import { PARTNER_APPLICATION, AUTH_USER } from "../constants/fields";
import {
  performWithPagination,
  getDateTime,
  getPartnerApplicationStatus,
} from "./index";

const performPartnerApplicationListItemData = (raw) => ({
  id: raw[PARTNER_APPLICATION.ID],
  telegram: raw[PARTNER_APPLICATION.TELEGRAM],
  description: raw[PARTNER_APPLICATION.DESCRIPTION],
});

export const performPartnerApplicationListData = performWithPagination((raw) =>
  raw.map(performPartnerApplicationListItemData)
);

export const performPartnerApplicationInfo = (raw) => ({
  id: raw[PARTNER_APPLICATION.ID],
  telegram: raw[PARTNER_APPLICATION.TELEGRAM],
  description: raw[PARTNER_APPLICATION.DESCRIPTION],
  status: getPartnerApplicationStatus(raw[PARTNER_APPLICATION.STATUS]),
  createDate: getDateTime(raw[PARTNER_APPLICATION.CREATE_DATE]),
  user: {
    id: raw[PARTNER_APPLICATION.USER]?.[AUTH_USER.ID],
    email: raw[PARTNER_APPLICATION.USER]?.[AUTH_USER.EMAIL],
  },
});
