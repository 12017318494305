import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ReduxWrapper } from "./containers/redux-wrapper";
import { AppRoutes } from "./containers/routes";
import { Onload } from "./containers/onload";
import { colors } from "./theme";

import "./utils/i18n";
import "./App.css";

const theme = {
  ...createTheme({
    palette: {
      primary: {
        main: colors.primary,
        light: "#E0EBFF",
        dark: "#3f19a5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#FF782D",
        light: "#ff9357",
        dark: "#b2541f",
        contrastText: "#fff",
      },
      text: {
        primary: colors.textPrimary,
        secondary: colors.textGray,
      },
    },
    typography: { fontFamily: ["Montserrat", "sans-serif"].join(",") },
  }),
};

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <div className="App">
          <ReduxWrapper>
            <Onload>
              <AppRoutes />
            </Onload>
          </ReduxWrapper>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
