import { axios } from "../utils/request";

import { API } from "../constants/api";
import { WITHDRAW_LIST } from "./index";
import { performWithdrawListData } from "../api/withdraw";

const setFail = (message) => ({
  type: WITHDRAW_LIST.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: WITHDRAW_LIST.LOAD_SUCCESS,
  data: performWithdrawListData(data),
});

const setLoading = () => ({
  type: WITHDRAW_LIST.LOAD_PENDING,
});

const updateSuccess = (data) => ({
  type: WITHDRAW_LIST.UPDATE_SUCCESS,
  data: performWithdrawListData(data),
});

const updatePending = () => ({
  type: WITHDRAW_LIST.UPDATE_PENDING,
});

export const resetStore = () => ({
  type: WITHDRAW_LIST.RESET_STORE,
});

export const getWithdrawList = ({
  skip,
  take,
  status: withdrawStatus,
  type,
}) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const { data } = await axios.get(
        API.WITHDRAW_LIST({ skip, take, status: withdrawStatus, type })
      );
      dispatch(setSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateWithdrawList = ({
  skip,
  take,
  status: withdrawStatus,
  type,
}) => {
  return async (dispatch) => {
    dispatch(updatePending());
    try {
      const { data } = await axios.get(
        API.WITHDRAW_LIST({ skip, take, status: withdrawStatus, type })
      );
      dispatch(updateSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
