import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getCookie } from "../../utils/cookie";
import { setAuthData } from "../../actions/auth";
import { setAuthorizationHeader } from "../../utils/request";
import { COOKIE_AUTH } from "../../constants";
import { getUser } from "../../actions/user";

export const Onload = ({ children }) => {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);

  const getToken = () => {
    const token = getCookie(COOKIE_AUTH);

    if (!token) {
      return null;
    }

    setAuthorizationHeader(token);

    dispatch(setAuthData(token));

    return token;
  };

  const handleUser = async (token) => {
    if (token) {
      await dispatch(getUser());
      setIsLoaded(true);
    } else {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    const token = getToken();

    handleUser(token);
  }, []);

  if (isLoaded) {
    return children;
  }

  return "Loading...";
};
