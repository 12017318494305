import { USER_ROLE_CHANGE } from '../actions';
import {
  initRequestState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
  resetRequestStatus,
} from '../utils/store';

export const initialState = {
  request: initRequestState(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ROLE_CHANGE.REQUEST_FAIL:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };
    case USER_ROLE_CHANGE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };
    case USER_ROLE_CHANGE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request),
      };
    case USER_ROLE_CHANGE.RESET_STATUS: {
      return {
        ...state,
        request: resetRequestStatus(state.request),
      };
    }
    default:
      return state;
  }
};
