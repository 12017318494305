export const AUTH_USER = {
  ID: "_id",
  CREATE_DATE: "createDate",
  BLOCKED: "blocked",
  ROLE: "role",
  EMAIL: "email",
  NICKNAME: "nickname",
  PASSWORD: "password",
  REFERRER: "referrer",
};

export const USER_STATISTICS = {
  TOTAL_GAMES: "gamesCount",
  TOTAL_BETS: "betsCount",
  TURNOVER: "betsTurnover",
};

export const PARTNER_APPLICATION = {
  ID: "_id",
  DESCRIPTION: "description",
  TELEGRAM: "telegram",
  CREATE_DATE: "createdAt",
  USER: "user",
  STATUS: "status",
};

export const USER_FIND = {
  FIND_VALUE: "findValue",
};

export const USER_ROLE_CHANGE = {
  ROLE: "role",
};

export const WITHDRAW = {
  ID: "_id",
  CURRENCY: "currency",
  VALUE: "value",
  ADDRESS: "address",
  STATUS: "status",
  USER: "user",
  CREATE_DATE: "createdAt",
  TYPE: "type",
  NETWORK: "network",
};

export const STATISTICS = {
  TURNOVER: "turnover",
  PROFIT: "profit",
  PROFIT_PERCENT: "profitPercent",
  CLASSIC: "classic",
  DOUBLE: "double",
  TOTAL: "total",
  TOTAL_BOTS: "totalBots",
  CURRENCIES: "currencies",
};

export const DEPOSIT = {
  ID: "_id",
  CURRENCY: "currency",
  VALUE: "value",
  USER: "user",
  CREATE_DATE: "createdAt",
  NETWORK: "network",
};

export const CURRENCY = {
  ID: "_id",
  CODE: "code",
  NAME: "name",
};

export const PROJECT_SETTINGS = {
  BOTS_ENABLED: "isBotsEnabled",
};
