import React from "react";
import styled from "styled-components";

import { ListGrid } from "../../components/grids";
import { Loader, Text, Link } from "../../components";
import { colors, sizes } from "../../theme";
import { ROUTES } from "../../constants/routes";

export const WithdrawInfo = (props) => {
  const {
    id,
    createDate,
    value,
    currency,
    network,
    user,
    isUpdating,
    status,
    address,
  } = props;

  return (
    <React.Fragment>
      <ListGrid>
        {isUpdating ? <Loader /> : null}
        <Field>
          <Heading>
            <Text tid="WITHDRAW.WITHDRAW_INFO.ID" />
            :&nbsp;
          </Heading>
          <Info>{id}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="WITHDRAW.WITHDRAW_INFO.VALUE" />
            :&nbsp;
          </Heading>
          <Info>
            {value} {currency}
          </Info>
        </Field>
        {network && (
          <Field>
            <Heading>
              <Text tid="WITHDRAW.WITHDRAW_INFO.NETWORK" />
              :&nbsp;
            </Heading>
            <Info>{network}</Info>
          </Field>
        )}
        <Field>
          <Heading>
            <Text tid="WITHDRAW.WITHDRAW_INFO.ADDRESS" />
            :&nbsp;
          </Heading>
          <Info>{address}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="WITHDRAW.WITHDRAW_INFO.USER" />
            :&nbsp;
          </Heading>
          <Info>
            <Link href={ROUTES.USER_INFO({ userId: user.id })} target="_blank">
              {user.email}
            </Link>
          </Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="WITHDRAW.WITHDRAW_INFO.STATUS" />
            :&nbsp;
          </Heading>
          <Info>{status}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="WITHDRAW.WITHDRAW_INFO.CREATE_DATE" />
            :&nbsp;
          </Heading>
          <Info>{createDate}</Info>
        </Field>
      </ListGrid>
    </React.Fragment>
  );
};

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  font-weight: 600;
  color: ${colors.textPrimary};
`;

const Info = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  color: ${colors.textPrimary};
`;
