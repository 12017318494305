import React from "react";
import PropTypes from "prop-types";

import { Loader, AlertSection, Divider, Text } from "../../components";
import { Box } from "../../components/cards";
import { SkeletonDataList } from "../../components/skeletons";
import { PartnerApplicationList } from "./List";
import { ListActionTitle } from "../../components/titles";
import { Tabs, Tab } from "../../components/tabs";
import { PARTNER_APPLICATION_STATUS } from "../../constants/static";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <Box variant="outlined">
      <SkeletonDataList size="big" />
    </Box>
  </React.Fragment>
);

export const PartnerApplicationView = ({
  loading,
  loaded,
  error,
  errorMessage,
  applications,
  paginationAction,
  updating,
  currentStatusType,
  handleChangeCurrentStatusType,
}) => {
  const isEmpty = loaded && applications.list.length === 0;

  return (
    <div>
      {updating && <Loader />}

      <Box variant="outlined">
        <ListActionTitle tid="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.TITLE" />

        <Tabs
          value={currentStatusType}
          onChange={handleChangeCurrentStatusType}
        >
          <Tab
            value={PARTNER_APPLICATION_STATUS.PENDING}
            label={
              <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.STATUS_PENDING" />
            }
          />
          <Tab
            value={PARTNER_APPLICATION_STATUS.APPROVED}
            label={
              <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.STATUS_APPROVED" />
            }
          />
          <Tab
            value={PARTNER_APPLICATION_STATUS.REJECTED}
            label={
              <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.STATUS_REJECTED" />
            }
          />
        </Tabs>
        <Divider />

        {loading && <LoaderComponent />}
        {loaded && applications.list.length !== 0 && (
          <React.Fragment>
            <PartnerApplicationList
              paginationAction={paginationAction}
              pagination={applications.pagination}
              updating={updating}
              list={applications.list}
            />
          </React.Fragment>
        )}
      </Box>

      <AlertSection
        error={error}
        errorMessage={errorMessage}
        info={isEmpty}
        infoMessage="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.EMPTY_LIST"
      />
    </div>
  );
};

PartnerApplicationView.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  users: PropTypes.object,
  updating: PropTypes.bool,
  paginationAction: PropTypes.func,
};
