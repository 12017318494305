import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { ListGrid } from "../../components/grids";
import { Loader, Text, Link } from "../../components";
import { colors, sizes } from "../../theme";
import { ROUTES } from "../../constants/routes";

export const PartnerApplicationInfo = (props) => {
  const { id, createDate, telegram, description, user, isUpdating, status } =
    props;

  return (
    <React.Fragment>
      <ListGrid>
        {isUpdating ? <Loader /> : null}
        <Field>
          <Heading>
            <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_INFO.ID" />
            :&nbsp;
          </Heading>
          <Info>{id}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_INFO.TELEGRAM" />
            :&nbsp;
          </Heading>
          <Info>{telegram}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_INFO.USER" />
            :&nbsp;
          </Heading>
          <Info>
            <Link href={ROUTES.USER_INFO({ userId: user.id })} target="_blank">
              {user.email}
            </Link>
          </Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_INFO.STATUS" />
            :&nbsp;
          </Heading>
          <Info>{status}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_INFO.CREATE_DATE" />
            :&nbsp;
          </Heading>
          <Info>{createDate}</Info>
        </Field>
        <Field style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <Heading>
            <Text tid="PARTNER_APPLICATION.PARTNER_APPLICATION_INFO.DESCRIPTION" />
            :&nbsp;
          </Heading>
          <Info>{description}</Info>
        </Field>
      </ListGrid>
    </React.Fragment>
  );
};

PartnerApplicationInfo.propTypes = {
  id: PropTypes.number.isRequired,
  login: PropTypes.string.isRequired,
  email: PropTypes.string,
  createDate: PropTypes.string.isRequired,
  pgpConfirm: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  referrer: PropTypes.object,
  paymentMethod: PropTypes.string,
  paymentAddress: PropTypes.string,
  telegram: PropTypes.string,
  referrerId: PropTypes.object,
  setReferrerId: PropTypes.func.isRequired,
  updateReferrer: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  font-weight: 600;
  color: ${colors.textPrimary};
`;

const Info = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  color: ${colors.textPrimary};
`;

const InputWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: 300px 100px;
  grid-column-gap: 20px;
`;
