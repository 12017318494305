import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAGINATION_OFFSET } from "../../constants/routes";
import {
  getUserList,
  updateUserList,
  resetStore,
} from "../../actions/userList";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isUpdatePending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

import { UserListView } from "./View";

export const UserList = () => {
  const {
    isLoading,
    isLoaded,
    isError,
    errorMessage,
    usersData,
    isUpdating,
    findValue,
  } = useSelector(({ userList: { data } }) => ({
    isLoading: isRequestPending(data),
    isLoaded: isRequestSuccess(data),
    isUpdating: isUpdatePending(data),
    isError: isRequestError(data),
    errorMessage: getErrorMessage(data),
    usersData: getData(data),
    findValue: data.findValue,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        updateUserList({
          skip: 0,
          take: PAGINATION_OFFSET.USER_LIST,
        })
      );
    } else {
      dispatch(
        getUserList({
          skip: 0,
          take: PAGINATION_OFFSET.USER_LIST,
        })
      );
    }

    return () => {
      dispatch(resetStore());
    };
  }, []);

  const paginationAction = () => {
    dispatch(
      updateUserList({
        findValue,
        skip: usersData.pagination.skip + PAGINATION_OFFSET.USER_LIST,
        take: PAGINATION_OFFSET.USER_LIST,
      })
    );
  };

  const loading = !isLoaded || isLoading;

  return (
    <UserListView
      loading={loading}
      loaded={isLoaded}
      updating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
      users={usersData}
      paginationAction={paginationAction}
    />
  );
};
