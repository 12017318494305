import { axios } from "../utils/request";
import { API } from "../constants/api";
import { STATISTICS_USERS_BALANCE } from "./index";
import { performStatisticsUsersBalance } from "../api/statistics";

const setFail = (message) => ({
  type: STATISTICS_USERS_BALANCE.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: STATISTICS_USERS_BALANCE.LOAD_SUCCESS,
  data: performStatisticsUsersBalance(data),
});

const setLoading = () => ({
  type: STATISTICS_USERS_BALANCE.LOAD_PENDING,
});

const setUpdating = () => ({
  type: STATISTICS_USERS_BALANCE.UPDATE_PENDING,
});

const setUpdated = (data) => ({
  type: STATISTICS_USERS_BALANCE.UPDATE_SUCCESS,
  data: performStatisticsUsersBalance(data),
});

export const getStatisticsUsersBalance = () => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.STATISTICS_USERS_BALANCE());
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateStatisticsUsersBalance = () => {
  return async (dispatch) => {
    dispatch(setUpdating());

    try {
      const response = await axios.get(API.STATISTICS_USERS_BALANCE());
      dispatch(setUpdated(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
