import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Text = ({ tid, values, withSpan }) => {
  const { t } = useTranslation();

  if (tid) {
    const text = t(tid, values);

    return withSpan ? <span>{text}</span> : <>{text}</>;
  }

  return null;
};

Text.propTypes = {
  tid: PropTypes.string.isRequired,
  values: PropTypes.object,
  withSpan: PropTypes.bool,
};
