import React from "react";
import styled from "styled-components";

import { ListTitle } from "../../components/titles";
import { ListGrid } from "../../components/grids";
import { Loader, Text } from "../../components";
import { colors, sizes } from "../../theme";

export const StatisticsUsersBalanceInfo = (props) => {
  const { total, currencies, isUpdating } = props;

  return (
    <React.Fragment>
      <div>
        <ListTitle tid="BALANCES.USERS_BALANCE.TITLE" />
        <ListGrid>
          {isUpdating ? <Loader /> : null}
          <Field>
            <Heading>
              <Text tid="BALANCES.USERS_BALANCE.TOTAL" />
              :&nbsp;
            </Heading>
            <Info>{total.sumUsdt.toFixed(2)}$</Info>
          </Field>
          {Object.keys(currencies).map((currency) => {
            const { amount, sumUsdt } = currencies[currency];

            return (
              <Field>
                <Heading>
                  {currency.toUpperCase()}
                  :&nbsp;
                </Heading>
                <Info>{`${amount.toFixed(
                  8
                )} ${currency.toUpperCase()} (${sumUsdt.toFixed(2)}$)`}</Info>
              </Field>
            );
          })}
        </ListGrid>
      </div>
    </React.Fragment>
  );
};

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  font-weight: 600;
  color: ${colors.textPrimary};
`;

const Info = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  color: ${colors.textPrimary};
`;
