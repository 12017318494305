/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "styled-components";
import { JSONTree } from "react-json-tree";
import { useTranslation } from "react-i18next";

const Job = ({ __src, __title }) => {
  const { t } = useTranslation();
  const title = t(`JOBS.TABLE_TITLES.${__title}`);
  return (
    <ClusterWrapper
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: 500,
        width: "100%",
      }}
    >
      <JobTitle style={{ fontSize: 14 }}>{title}</JobTitle>
      <JSONTree
        style={{ minHeight: "100%", borderRadius: 6, padding: 6 }}
        theme={{
          author: "wimer hazenberg (http://www.monokai.nl)",
          scheme: "monokai",
          base00: "#000000",
          base01: "#383830",
          base02: "#49483e",
          base03: "#75715e",
          base04: "#a59f85",
          base05: "#f8f8f2",
          base06: "#f5f4f1",
          base07: "#f9f8f5",
          base08: "#f92672",
          base09: "#fd971f",
          base0A: "#f4bf75",
          base0B: "#a6e22e",
          base0C: "#a1efe4",
          base0D: "#66d9ef",
          base0E: "#ae81ff",
          base0F: "#cc6633",
        }}
        data={__src}
        hideRoot
      />
    </ClusterWrapper>
  );
};

export const JobsView = ({ jobsStorage, spacePressed }) => {
  const { t } = useTranslation();

  const {
    mGames_classicIteration = {},
    mGames_doubleIteration = {},
    mGames_crashIteration = {},
    mGames_cardsIteration = {},
    mBalances_calculate = {},
    mBalances_createOne = {},
    mBalances_create = {},
    mBets_add = {},
  } = jobsStorage;

  return (
    <JobsWrapper>
      {spacePressed ? t("JOBS.LIVE_UPDATES_ON") : t("JOBS.LIVE_UPDATES_OFF")}
      <div>
        <Job
          __src={mGames_classicIteration}
          __title="mGames_classicIteration"
        />
        <Job __src={mGames_doubleIteration} __title="mGames_doubleIteration" />
        <Job __src={mGames_crashIteration} __title="mGames_crashIteration" />
        <Job __src={mGames_cardsIteration} __title="mGames_cardsIteration" />
      </div>
      <div>
        <Job __src={mBalances_calculate} __title="mBalances_calculate" />
        <Job __src={mBalances_createOne} __title="mBalances_createOne" />
        <Job __src={mBalances_create} __title="mBalances_create" />
        <Job __src={mBets_add} __title="mBets_add" />
      </div>
    </JobsWrapper>
  );
};

const ClusterWrapper = styled.div`
  & > ul {
    padding: 2px 6px !important;
    border-radius: 6px;
    min-height: 100%;
  }
`;

const JobTitle = styled.div`
  font-size: 16px !important;
  font-weight: bold;
`;

const JobsWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  gap: 12px;

  * {
    font-size: 12px !important;
  }

  & > div {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    border-radius: 12px;
    min-height: 350px;
    display: flex;
    padding: 6px;
    width: 100%;
    gap: 6px;
  }

  & div {
    ::-webkit-scrollbar {
      display: none !important;
    }
  }

  & > div > div {
    height: 500px;
  }

  @media (max-width: 1300px) {
    & > div {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
    }

    & > div > div {
      height: 500px;
    }
  }
`;
