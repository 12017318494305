import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { isRequestPending, isRequestSuccess } from "../../utils/store";
import {
  getProjectSettingsData,
  updateProjectSettingsData,
} from "../../actions/projectSettings";
import { Loader } from "../../components";

export const ProjectSettings = () => {
  const dispatch = useDispatch();

  const { isLoading, isLoaded } = useSelector(
    ({ projectSettings: { data: projectSettings } }) => ({
      isLoading: isRequestPending(projectSettings),
      isLoaded: isRequestSuccess(projectSettings),
    })
  );

  useEffect(() => {
    if (isLoaded) {
      dispatch(updateProjectSettingsData());
    } else {
      dispatch(getProjectSettingsData());
    }
  }, []);

  return <React.Fragment>{isLoading && <Loader />}</React.Fragment>;
};
