import meta from "./meta.json";
import validation from "./validation.json";
import error from "./error.json";
import navigation from "./navigation.json";
import staticData from "./static.json";
import login from "./login.json";
import logout from "./logout.json";
import user from "./user.json";
import modal from "./modal.json";
import withdraw from "./withdraw.json";
import partnerApplication from "./partnerApplication.json";
import deposit from "./deposit.json";
import statistics from "./statistics.json";
import projectSettings from "./projecSettings.json";
import jobs from "./jobs.json";
import storage from "./storage.json";
import balances from "./balances.json";

export default {
  translation: {
    META: meta,
    VALIDATION: validation,
    ERROR: error,
    NAVIGATION: navigation,
    STATIC: staticData,
    LOGIN: login,
    LOGOUT: logout,
    USER: user,
    MODAL: modal,
    WITHDRAW: withdraw,
    PARTNER_APPLICATION: partnerApplication,
    DEPOSIT: deposit,
    STATISTICS: statistics,
    PROJECT_SETTINGS: projectSettings,
    JOBS: jobs,
    STORAGE: storage,
    BALANCES: balances,
  },
};
