import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAGINATION_OFFSET } from "../../constants/routes";
import { UserFindView } from "./View";
import {
  isRequestPending,
  isRequestSuccess,
  isUpdatePending,
} from "../../utils/store";
import {
  changeFindValue,
  getUserList,
  updateUserList,
} from "../../actions/userList";

export const UserFind = () => {
  const dispatch = useDispatch();

  const { isLoading, isLoaded, isUpdating, findValue } = useSelector(
    ({ userList }) => ({
      findValue: userList.findValue,
      isLoading: isRequestPending(userList.data),
      isUpdating: isUpdatePending(userList.data),
      isLoaded: isRequestSuccess(userList.data),
    })
  );

  const isFormDisabled = () => {
    return !findValue || isLoading || isUpdating;
  };

  const handleChangeFindValue = (value) => {
    dispatch(changeFindValue(value));
  };

  const submitFindUser = async (e) => {
    e.preventDefault();

    if (isLoaded) {
      await dispatch(
        updateUserList({
          findValue,
          skip: 0,
          take: PAGINATION_OFFSET.USER_LIST,
        })
      );
    } else {
      await dispatch(
        getUserList({
          findValue,
          skip: 0,
          take: PAGINATION_OFFSET.USER_LIST,
        })
      );
    }
  };

  return (
    <form onSubmit={submitFindUser}>
      <UserFindView
        disabled={isFormDisabled()}
        loading={isLoading}
        findValue={findValue}
        handleChangeFindValue={handleChangeFindValue}
      />
    </form>
  );
};
