import React from "react";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";

export const Box = ({ variant = "elevation", ...props }) => (
  <Paper variant={variant} {...props} />
);

Box.propTypes = {
  variant: PropTypes.string,
};
