import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import { sizes, colors } from "../theme";

export const Link = ({
  color = "textPrimary",
  underline = "always",
  onClick,
  href,
  target = "_self",
  download,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <LinkStyled
      {...props}
      color={color}
      underline={underline}
      href={href}
      target={target}
      download={download}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          return onClick();
        }

        if (href[0] === "/" && target !== "_blank") {
          e.preventDefault();
          return navigate(href);
        }

        return null;
      }}
    />
  );
};

Link.propTypes = {
  color: PropTypes.string,
  underline: PropTypes.string,
  target: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  download: PropTypes.func,
};

const LinkStyled = styled(RouterLink)`
  && {
    cursor: pointer;
    color: ${({ color }) => colors[color] || colors.textPrimary} !important;
    opacity: 1 !important;
    transition: opacity ${sizes.transition.default};

    &:hover {
      color: ${({ color }) => colors[color] || colors.textPrimary} !important;
      opacity: ${sizes.opacity.hover} !important;
    }
  }
`;
