import React from "react";
import styled from "styled-components";

import { ListGrid } from "../../components/grids";
import { Loader, Text } from "../../components";
import { colors, sizes } from "../../theme";

export const UserStatisticsInfo = (props) => {
  const { totalGames, totalBets, turnover, isUpdating } = props;

  return (
    <React.Fragment>
      <ListGrid>
        {isUpdating ? <Loader /> : null}

        <Field>
          <Heading>
            <Text tid="USER.USER_STATISTICS_INFO.TOTAL_GAMES" />
            :&nbsp;
          </Heading>
          <Info>{totalGames}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_STATISTICS_INFO.TOTAL_BETS" />
            :&nbsp;
          </Heading>
          <Info>{totalBets}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_STATISTICS_INFO.TURNOVER" />
            :&nbsp;
          </Heading>
          <Info>{turnover}</Info>
        </Field>
      </ListGrid>
    </React.Fragment>
  );
};

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  font-weight: 600;
  color: ${colors.textPrimary};
`;

const Info = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  color: ${colors.textPrimary};
`;

const InputWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: 300px 100px;
  grid-column-gap: 20px;
`;
