import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ListGrid } from "../../components/grids";
import { Loader, Text, Link } from "../../components";
import { colors, sizes } from "../../theme";
import { getUserRole } from "../../api";
import { ROUTES } from "../../constants/routes";

export const UserInfo = (props) => {
  const {
    email,
    id,
    createDate,
    role,
    referrer,
    nickname,
    users,
    referrerId,
    setReferrerId,
    updateReferrer,
    isUpdating,
  } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ListGrid>
        {isUpdating ? <Loader /> : null}

        <Field>
          <Heading>
            <Text tid="USER.USER_INFO.ID" />
            :&nbsp;
          </Heading>
          <Info>{id}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_INFO.EMAIL" />
            :&nbsp;
          </Heading>
          <Info>
            {email || <Text tid="USER.USER_INFO.EMAIL_PLACEHOLDER" />}
          </Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_INFO.NICKNAME" />
            :&nbsp;
          </Heading>
          <Info>{nickname}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_INFO.REFERRER" />
            :&nbsp;
          </Heading>
          <Info>
            {referrer.email ? (
              <Link
                href={ROUTES.USER_INFO({ userId: referrer.id })}
                target="_blank"
              >
                {referrer.email}
              </Link>
            ) : (
              <Text tid="USER.USER_INFO.REFERRER_PLACEHOLDER" />
            )}
          </Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_INFO.ROLE" />
            :&nbsp;
          </Heading>
          <Info>{getUserRole(role)}</Info>
        </Field>
        <Field>
          <Heading>
            <Text tid="USER.USER_INFO.CREATE_DATE" />
            :&nbsp;
          </Heading>
          <Info>{createDate}</Info>
        </Field>

        {/* <InputWrapper>
          <SelectField
            items={users}
            label={t("USER.USER_INFO.REFERRER")}
            value={referrerId || referrer?.id}
            onChange={(e) => setReferrerId(parseInt(e.target.value, 10))}
          />

          {referrerId && referrerId !== referrer?.id ? (
            <ButtonPrimary
              color="primary"
              variant="outlined"
              onClick={updateReferrer}
              disabled={isUpdating}
            >
              <Text tid="USER.USER_INFO.UPDATE_BUTTON" />
            </ButtonPrimary>
          ) : null}
        </InputWrapper> */}
      </ListGrid>
    </React.Fragment>
  );
};

UserInfo.propTypes = {
  id: PropTypes.number.isRequired,
  login: PropTypes.string.isRequired,
  email: PropTypes.string,
  createDate: PropTypes.string.isRequired,
  pgpConfirm: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  referrer: PropTypes.object,
  paymentMethod: PropTypes.string,
  paymentAddress: PropTypes.string,
  telegram: PropTypes.string,
  referrerId: PropTypes.object,
  setReferrerId: PropTypes.func.isRequired,
  updateReferrer: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  font-weight: 600;
  color: ${colors.textPrimary};
`;

const Info = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  color: ${colors.textPrimary};
`;

const InputWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: 300px 100px;
  grid-column-gap: 20px;
`;
