import { axios, setAuthorization } from "../utils/request";
import { ROUTES } from "../constants/routes";
import { API } from "../constants/api";
import { setAuthData } from "./auth";
import { getUser } from "./user";

export const LOGIN = {
  LOADING: "LOGIN.LOADING",
  SUCCESS: "LOGIN.SUCCESS",
  ERROR: "LOGIN.ERROR",
};

const setLoading = () => ({
  type: LOGIN.LOADING,
});

const setSuccess = (data) => ({
  type: LOGIN.SUCCESS,
  data,
});

const setError = (message) => ({
  type: LOGIN.ERROR,
  message,
});

export const logOut = () => {
  setAuthorization(null);

  window.location = ROUTES.LOGIN;
};

export const login = (payload, navigate) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .post(API.LOGIN, payload)
      .then(({ data }) => {
        setAuthorization(data.accessToken);
        dispatch(setAuthData(data.accessToken));
        dispatch(setSuccess(data.accessToken));
        dispatch(getUser());
        navigate(ROUTES.HOME);
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};
