import { axios } from "../utils/request";
import { API } from "../constants/api";
import { STATISTICS_PROFIT } from "./index";
import { performStatisticsProfit } from "../api/statistics";

const setFail = (message) => ({
  type: STATISTICS_PROFIT.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: STATISTICS_PROFIT.LOAD_SUCCESS,
  data: performStatisticsProfit(data),
});

const setLoading = () => ({
  type: STATISTICS_PROFIT.LOAD_PENDING,
});

const setUpdating = () => ({
  type: STATISTICS_PROFIT.UPDATE_PENDING,
});

const setUpdated = (data) => ({
  type: STATISTICS_PROFIT.UPDATE_SUCCESS,
  data: performStatisticsProfit(data),
});

export const getStatisticsProfit = () => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.STATISTICS_PROFIT());
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateStatisticsProfit = () => {
  return async (dispatch) => {
    dispatch(setUpdating());

    try {
      const response = await axios.get(API.STATISTICS_PROFIT());
      dispatch(setUpdated(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
