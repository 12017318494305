import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextFieldSkeleton } from "./TextField";

import { sizes, colors } from "../../theme";

export const createItems = (items) => {
  const { t } = useTranslation();

  return items.map(({ id, tid, name, value, disabled }) => (
    <MenuItem disabled={disabled} key={id} value={value}>
      {name || t(tid)}
    </MenuItem>
  ));
};

export const SelectField = ({
  className,
  variant = "outlined",
  label,
  items,
  helperText,
  placeholder,
  error,
  size,
  multiple,
  value,
  loading,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  const inputLabel = React.useRef(null);

  return (
    <Container
      size={size}
      variant={variant}
      loading={loading}
      className={`Mui-select ${className}`}
      error={error}
    >
      {loading && <TextFieldSkeleton />}
      <InputLabel id={`select-label-${label}`} ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        labelId={`select-label-${label}`}
        size={size}
        label={label}
        multiple={multiple}
        variant={variant || "outlined"}
        // defaultValue="ADMIN"
        displayEmpty
        value={multiple && !value ? [] : value}
        disabled={loading || disabled}
        {...props}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {createItems(items, t)}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Container>
  );
};

const Container = styled(FormControl)`
  width: 100%;
  position: relative;

  & legend {
    z-index: 10;
    background-color: ${({ loading }) => (loading ? "transparent" : "#fff")};
  }
  & fieldset {
    transition: ${sizes.transition.field} !important;
    border-width: 1px !important;
    border-color: ${colors.gray};
  }
  & .MuiSelect-root {
    background-color: transparent !important;
  }
`;
