import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { UserMenuView } from "./View";
import { USER_MENU } from "../../constants/routes";

export const UserMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userId } = useParams();

  const activeTab = USER_MENU.find(
    ({ path }) => path({ userId }) === location.pathname
  ).id;

  const handleClickTab = (e, id) => {
    const { path } = USER_MENU.find((link) => link.id === id);
    navigate(path({ userId }));
  };

  return (
    <UserMenuView
      tabs={USER_MENU}
      activeTab={activeTab}
      handleClickTab={handleClickTab}
    />
  );
};
