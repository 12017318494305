import { axios } from "../utils/request";

import { API } from "../constants/api";
import { STAFF_LIST } from "./index";
import { performStaffListData } from "../api/user";

const setFail = (message) => ({
  type: STAFF_LIST.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: STAFF_LIST.LOAD_SUCCESS,
  data: performStaffListData(data),
});

const setLoading = () => ({
  type: STAFF_LIST.LOAD_PENDING,
});

const updateSuccess = (data) => ({
  type: STAFF_LIST.UPDATE_SUCCESS,
  data: performStaffListData(data),
});

const updatePending = () => ({
  type: STAFF_LIST.UPDATE_PENDING,
});

export const getStaffList = ({ skip, take }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const { data } = await axios.get(API.STAFF_LIST({ skip, take }));
      dispatch(setSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateStaffList = ({ skip, take }) => {
  return async (dispatch) => {
    dispatch(updatePending());
    try {
      const { data } = await axios.get(API.STAFF_LIST({ skip, take }));
      dispatch(updateSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
