import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Text, Link } from "../../components";
import { logOut } from "../../actions/login";

export const MobileMenu = ({ items, onSelect }) => {
  const navigate = useNavigate();

  return (
    <List component="div">
      {items.map(({ id, path, tid }) => (
        <React.Fragment key={id}>
          <ListItem
            component={Link}
            href={path}
            onClick={(e) => {
              navigate(path);
              onSelect();
            }}
          >
            <ListItemText primary={<Text tid={tid} />} />
          </ListItem>
        </React.Fragment>
      ))}
      <ListItem underline="none" onClick={logOut}>
        <Text tid="NAVIGATION.PATH.LOGOUT" />
      </ListItem>
    </List>
  );
};

MobileMenu.propTypes = {
  items: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};
