import { axios } from "../utils/request";

import { API } from "../constants/api";
import { PARTNER_APPLICATION_LIST } from "./index";
import { performPartnerApplicationListData } from "../api/partner";

const setFail = (message) => ({
  type: PARTNER_APPLICATION_LIST.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: PARTNER_APPLICATION_LIST.LOAD_SUCCESS,
  data: performPartnerApplicationListData(data),
});

const setLoading = () => ({
  type: PARTNER_APPLICATION_LIST.LOAD_PENDING,
});

const updateSuccess = (data) => ({
  type: PARTNER_APPLICATION_LIST.UPDATE_SUCCESS,
  data: performPartnerApplicationListData(data),
});

const updatePending = () => ({
  type: PARTNER_APPLICATION_LIST.UPDATE_PENDING,
});

export const resetStore = () => ({
  type: PARTNER_APPLICATION_LIST.RESET_STORE,
});

export const getPartnerApplicationList = ({
  skip,
  take,
  status: applicationStatus,
}) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const { data } = await axios.get(
        API.PARTNER_APPLICATION_LIST({ skip, take, status: applicationStatus })
      );
      dispatch(setSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updatePartnerApplicationList = ({
  skip,
  take,
  status: applicationStatus,
}) => {
  return async (dispatch) => {
    dispatch(updatePending());
    try {
      const { data } = await axios.get(
        API.PARTNER_APPLICATION_LIST({ skip, take, status: applicationStatus })
      );
      dispatch(updateSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
