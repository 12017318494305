import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SectionLayout } from "./Section";
import { spacing } from "../../theme";

import { UserMenu } from "../../containers/user-menu";

export const UserInfoLayout = ({ children }) => {
  return (
    <React.Fragment>
      <SectionLayout indent>
        <Content>
          <UserMenu />
          <SectionLayout>{children}</SectionLayout>
        </Content>
      </SectionLayout>
    </React.Fragment>
  );
};

UserInfoLayout.propTypes = {
  children: PropTypes.node,
};

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: ${spacing(8)};
  align-items: flex-start;
  @media all and (max-width: 1200px) {
    grid-template-columns: 100%;
    grid-row-gap: ${spacing(4)};
  }
`;
