import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getUserStatisticsInfo,
  updateUserStatisticsInfo,
} from "../../actions/userStatisticsInfo";
import { UserStatisticsInfoView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const UserStatisticsInfo = () => {
  const { data, isLoading, isLoaded, isError, errorMessage, isUpdating } =
    useSelector(({ userStatisticsInfo: { data: userStatisticsInfo } }) => ({
      data: getData(userStatisticsInfo),
      isLoading: isRequestPending(userStatisticsInfo),
      isLoaded: isRequestSuccess(userStatisticsInfo),
      isError: isRequestError(userStatisticsInfo),
      errorMessage: getErrorMessage(userStatisticsInfo),
      isUpdating: userStatisticsInfo.updating,
    }));

  const { userId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded && userId === data._id) {
      dispatch(updateUserStatisticsInfo({ userId }));
    } else {
      dispatch(getUserStatisticsInfo({ userId }));
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <UserStatisticsInfoView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      isUpdating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
