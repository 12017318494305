// User info data
export const USER_INFO = {
  LOAD_SUCCESS: "USER_INFO.LOAD_SUCCESS",
  LOAD_PENDING: "USER_INFO.LOAD_PENDING",
  LOAD_FAIL: "USER_INFO.LOAD_FAIL",
  SET_DATA: "USER_INFO.SET_DATA",
  UPDATE_SUCCESS: "USER_INFO.UPDATE_SUCCESS",
  UPDATE_PENDING: "USER_INFO.UPDATE_PENDING",
  SET_REFERRER: "USER_INFO.SET_REFERRER",
};

export const USER_STATISTICS_INFO = {
  LOAD_SUCCESS: "USER_STATISTICS_INFO.LOAD_SUCCESS",
  LOAD_PENDING: "USER_STATISTICS_INFO.LOAD_PENDING",
  LOAD_FAIL: "USER_STATISTICS_INFO.LOAD_FAIL",
  UPDATE_SUCCESS: "USER_STATISTICS_INFO.UPDATE_SUCCESS",
  UPDATE_PENDING: "USER_STATISTICS_INFO.UPDATE_PENDING",
};

export const USER_LIST = {
  LOAD_PENDING: "USER_LIST.LOAD_PENDING",
  LOAD_SUCCESS: "USER_LIST.LOAD_SUCCESS",
  LOAD_FAIL: "USER_LIST.LOAD_FAIL",
  UPDATE_PENDING: "USER_LIST.UPDATE_PENDING",
  UPDATE_SUCCESS: "USER_LIST.UPDATE_SUCCESS",
  RESET_STORE: "USER_LIST.RESET_STORE",
  CHANGE_FIND_VALUE: "USER_LIST.CHANGE_FIND_VALUE",
};

export const STAFF_LIST = {
  LOAD_PENDING: "STAFF_LIST.LOAD_PENDING",
  LOAD_SUCCESS: "STAFF_LIST.LOAD_SUCCESS",
  LOAD_FAIL: "STAFF_LIST.LOAD_FAIL",
  UPDATE_PENDING: "STAFF_LIST.UPDATE_PENDING",
  UPDATE_SUCCESS: "STAFF_LIST.UPDATE_SUCCESS",
};

export const USER_BLOCKING = {
  REQUEST_PENDING: "USER_BLOCKING.REQUEST_PENDING",
  REQUEST_SUCCESS: "USER_BLOCKING.REQUEST_SUCCESS",
  REQUEST_FAIL: "USER_BLOCKING.REQUEST_FAIL",
  RESET_STATUS: "USER_BLOCKING.RESET_STATUS",
};

export const USER_ROLE_CHANGE = {
  REQUEST_FAIL: "USER_ROLE_CHANGE.REQUEST_FAIL",
  REQUEST_PENDING: "USER_ROLE_CHANGE.REQUEST_PENDING",
  REQUEST_SUCCESS: "USER_ROLE_CHANGE.REQUEST_SUCCESS",
  RESET_STATUS: "USER_ROLE_CHANGE.RESET_STATUS",
};

export const PARTNER_APPLICATION_LIST = {
  LOAD_PENDING: "PARTNER_APPLICATION_LIST.LOAD_PENDING",
  LOAD_SUCCESS: "PARTNER_APPLICATION_LIST.LOAD_SUCCESS",
  LOAD_FAIL: "USERPARTNER_APPLICATION_LIST_LIST.LOAD_FAIL",
  UPDATE_PENDING: "PARTNER_APPLICATION_LIST.UPDATE_PENDING",
  UPDATE_SUCCESS: "PARTNER_APPLICATION_LIST.UPDATE_SUCCESS",
  RESET_STORE: "PARTNER_APPLICATION_LIST.RESET_STORE",
};

export const PARTNER_APPLICATION_INFO = {
  LOAD_SUCCESS: "PARTNER_APPLICATION_INFO.LOAD_SUCCESS",
  LOAD_PENDING: "PARTNER_APPLICATION_INFO.LOAD_PENDING",
  LOAD_FAIL: "PARTNER_APPLICATION_INFO.LOAD_FAIL",
  UPDATE_SUCCESS: "PARTNER_APPLICATION_INFO.UPDATE_SUCCESS",
  UPDATE_PENDING: "PARTNER_APPLICATION_INFO.UPDATE_PENDING",
};

export const PARTNER_APPLICATION_STATUS = {
  LOAD_SUCCESS: "PARTNER_APPLICATION_STATUS.LOAD_SUCCESS",
  LOAD_PENDING: "PARTNER_APPLICATION_STATUS.LOAD_PENDING",
  LOAD_FAIL: "PARTNER_APPLICATION_STATUS.LOAD_FAIL",
};

export const WITHDRAW_LIST = {
  LOAD_PENDING: "WITHDRAW_LIST.LOAD_PENDING",
  LOAD_SUCCESS: "WITHDRAW_LIST.LOAD_SUCCESS",
  LOAD_FAIL: "WITHDRAW_LIST.LOAD_FAIL",
  UPDATE_PENDING: "WITHDRAW_LIST.UPDATE_PENDING",
  UPDATE_SUCCESS: "WITHDRAW_LIST.UPDATE_SUCCESS",
  RESET_STORE: "WITHDRAW_LIST.RESET_STORE",
};

export const WITHDRAW_INFO = {
  LOAD_SUCCESS: "WITHDRAW_INFO.LOAD_SUCCESS",
  LOAD_PENDING: "WITHDRAW_INFO.LOAD_PENDING",
  LOAD_FAIL: "WITHDRAW_INFO.LOAD_FAIL",
  UPDATE_SUCCESS: "WITHDRAW_INFO.UPDATE_SUCCESS",
  UPDATE_PENDING: "WITHDRAW_INFO.UPDATE_PENDING",
};

export const WITHDRAW_STATUS = {
  LOAD_SUCCESS: "WITHDRAW_STATUS.LOAD_SUCCESS",
  LOAD_PENDING: "WITHDRAW_STATUS.LOAD_PENDING",
  LOAD_FAIL: "WITHDRAW_STATUS.LOAD_FAIL",
};

export const DEPOSIT_LIST = {
  LOAD_PENDING: "DEPOSIT_LIST.LOAD_PENDING",
  LOAD_SUCCESS: "DEPOSIT_LIST.LOAD_SUCCESS",
  LOAD_FAIL: "DEPOSIT_LIST.LOAD_FAIL",
  UPDATE_PENDING: "DEPOSIT_LIST.UPDATE_PENDING",
  UPDATE_SUCCESS: "DEPOSIT_LIST.UPDATE_SUCCESS",
  RESET_STORE: "DEPOSIT_LIST.RESET_STORE",
};

export const DEPOSIT_INFO = {
  LOAD_SUCCESS: "DEPOSIT_INFO.LOAD_SUCCESS",
  LOAD_PENDING: "DEPOSIT_INFO.LOAD_PENDING",
  LOAD_FAIL: "DEPOSIT_INFO.LOAD_FAIL",
  UPDATE_SUCCESS: "DEPOSIT_INFO.UPDATE_SUCCESS",
  UPDATE_PENDING: "DEPOSIT_INFO.UPDATE_PENDING",
};

export const STATISTICS_PROFIT = {
  LOAD_SUCCESS: "STATISTICS_PROFIT.LOAD_SUCCESS",
  LOAD_PENDING: "STATISTICS_PROFIT.LOAD_PENDING",
  LOAD_FAIL: "STATISTICS_PROFIT.LOAD_FAIL",
  UPDATE_SUCCESS: "STATISTICS_PROFIT.UPDATE_SUCCESS",
  UPDATE_PENDING: "STATISTICS_PROFIT.UPDATE_PENDING",
};

export const STATISTICS_USERS_BALANCE = {
  LOAD_SUCCESS: "STATISTICS_USERS_BALANCE.LOAD_SUCCESS",
  LOAD_PENDING: "STATISTICS_USERS_BALANCE.LOAD_PENDING",
  LOAD_FAIL: "STATISTICS_USERS_BALANCE.LOAD_FAIL",
  UPDATE_SUCCESS: "STATISTICS_USERS_BALANCE.UPDATE_SUCCESS",
  UPDATE_PENDING: "STATISTICS_USERS_BALANCE.UPDATE_PENDING",
};

export const STATISTICS_BOTS_BALANCE = {
  LOAD_SUCCESS: "STATISTICS_BOTS_BALANCE.LOAD_SUCCESS",
  LOAD_PENDING: "STATISTICS_BOTS_BALANCE.LOAD_PENDING",
  LOAD_FAIL: "STATISTICS_BOTS_BALANCE.LOAD_FAIL",
  UPDATE_SUCCESS: "STATISTICS_BOTS_BALANCE.UPDATE_SUCCESS",
  UPDATE_PENDING: "STATISTICS_BOTS_BALANCE.UPDATE_PENDING",
};

export const STATISTICS_USERS_ADD_BALANCE = {
  LOAD_SUCCESS: "STATISTICS_USERS_ADD_BALANCE.LOAD_SUCCESS",
  LOAD_PENDING: "STATISTICS_USERS_ADD_BALANCE.LOAD_PENDING",
  LOAD_FAIL: "STATISTICS_USERS_ADD_BALANCE.LOAD_FAIL",
  UPDATE_SUCCESS: "STATISTICS_USERS_ADD_BALANCE.UPDATE_SUCCESS",
  UPDATE_PENDING: "STATISTICS_USERS_ADD_BALANCE.UPDATE_PENDING",
};

export const PROJECT_SETTINGS = {
  LOAD_SUCCESS: "PROJECT_SETTINGS.LOAD_SUCCESS",
  LOAD_PENDING: "PROJECT_SETTINGS.LOAD_PENDING",
  LOAD_FAIL: "PROJECT_SETTINGS.LOAD_FAIL",
  UPDATE_SUCCESS: "PROJECT_SETTINGS.UPDATE_SUCCESS",
  UPDATE_PENDING: "PROJECT_SETTINGS.UPDATE_PENDING",
};

export const PROJECT_SETTINGS_BOTS = {
  LOAD_SUCCESS: "PROJECT_SETTINGS_BOTS.LOAD_SUCCESS",
  LOAD_PENDING: "PROJECT_SETTINGS_BOTS.LOAD_PENDING",
  LOAD_FAIL: "PROJECT_SETTINGS_BOTS.LOAD_FAIL",
};
