export const colors = {
  primary: "#367aff",
  lightPrimary: "#E0EBFF",
  textPrimary: "#323A53",
  secondary: "#FF782D",
  border: "rgba(0, 0, 0, 0.12)",
  gray: "#D6DEF5",
  dark: "rgba(0, 0, 0, 0.12)",
  textGray: "#7e869d",
  background: "#fafafa",
  error: "#FF4E4E",
  warn: "orange",
  success: "#4caf50",
  new: "#4caf50",
  inProgress: "#2196f3",
  active: "#4caf50",
  disabled: "#a2ad9c",
  backgroundLight: "#FAFCFF",
  green: "#4FB973",
};
