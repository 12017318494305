const API_SLUG = "api";

const getDomain = () =>
  window.location.hostname.replace("www.", "").split(".").slice(-2).join(".");

const getApiServer = () => {
  if (process.env.NODE_ENV === "development")
    return `${process.env.REACT_APP_API}/admin`;

  const domain = getDomain();

  return `https://${API_SLUG}.${domain}/admin`;
};

const API_SERVER = getApiServer();

export const API = {
  LOGIN: `${API_SERVER}/auth/login`,
  USER_DATA: `${API_SERVER}/auth/user`,
  USER_LIST: ({ findValue, skip, take }) =>
    `${API_SERVER}/user/${findValue}?skip=${skip}&take=${take}`,
  PARTNER_APPLICATION_LIST: ({ status, skip, take }) =>
    `${API_SERVER}/partner/application/list/${status}?skip=${skip}&take=${take}`,
  PARTNER_APPLICATION_INFO: ({ applicationId }) =>
    `${API_SERVER}/partner/application/${applicationId}/info`,
  PARTNER_APPLICATION_CHANGE_STATUS: ({ applicationId }) =>
    `${API_SERVER}/partner/application/${applicationId}/change-status`,
  STAFF_LIST: ({ skip, take }) =>
    `${API_SERVER}/user/staff/list?skip=${skip}&take=${take}`,
  USER_INFO: ({ userId }) => `${API_SERVER}/user/${userId}/info`,
  USER_STATISTICS_INFO: ({ userId }) =>
    `${API_SERVER}/user/${userId}/statistics/info`,
  UPDATE_USER_REFERRER: ({ userId }) => `${API_SERVER}/user/${userId}/referrer`,
  USER_ROLE: ({ userId }) => `${API_SERVER}/user/${userId}/role`,
  USER_BLOCK: ({ userId }) => `${API_SERVER}/user/${userId}/block`,
  DEPOSIT_LIST: ({ skip, take, status }) =>
    `${API_SERVER}/deposit/list/${status}?skip=${skip}&take=${take}`,
  DEPOSIT_INFO: ({ depositId }) => `${API_SERVER}/deposit/${depositId}/info`,
  WITHDRAW_LIST: ({ skip, take, status, type }) =>
    `${API_SERVER}/withdraw/list/${status}?skip=${skip}&take=${take}&type=${type}`,
  WITHDRAW_INFO: ({ withdrawId }) =>
    `${API_SERVER}/withdraw/${withdrawId}/info`,
  WITHDRAW_CHANGE_STATUS: ({ withdrawId }) =>
    `${API_SERVER}/withdraw/${withdrawId}/change-status`,
  STATISTICS_PROFIT: () => `${API_SERVER}/statistics/profit`,
  STATISTICS_USERS_BALANCE: () => `${API_SERVER}/statistics/balances`,
  STATISTICS_BOTS_BALANCE: () => `${API_SERVER}/statistics/balances/bots`,
  STATISTICS_USERS_ADD_BALANCE: () => `${API_SERVER}/statistics/balances/add`,
  PROJECT_SETTINGS_DATA: () => `${API_SERVER}/project-settings/data`,
  PROJECT_SETTINGS_BOTS: () => `${API_SERVER}/project-settings/bots`,
  JOBS_STORAGE: () => `${API_SERVER}/jobs-storage`,
  STORAGE: () => `${API_SERVER}/storage`,
};
