import { axios } from "../utils/request";
import { API } from "../constants/api";
import { PARTNER_APPLICATION_STATUS } from "./index";
import { getPartnerApplicationInfo } from "./partnerApplicationInfo";

const setFail = (message) => ({
  type: PARTNER_APPLICATION_STATUS.LOAD_FAIL,
  message,
});

const setSuccess = () => ({
  type: PARTNER_APPLICATION_STATUS.LOAD_SUCCESS,
});

const setLoading = () => ({
  type: PARTNER_APPLICATION_STATUS.LOAD_PENDING,
});

export const changePartnerApplicationStatus = ({
  applicationId,
  partnerPercent,
  status,
}) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.patch(
        API.PARTNER_APPLICATION_CHANGE_STATUS({ applicationId }),
        { status, partnerPercent }
      );
      dispatch(setSuccess(response.data));
      dispatch(getPartnerApplicationInfo({ applicationId }));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
