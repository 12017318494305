import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Progress } from "../../components";
import { elementVisible, throttle } from "../../utils";
import { OBSERVE_INTERVAL } from "../../constants";
import { spacing } from "../../theme";

const PAGINATION_ID = "PAGINATION_ID";

class PaginationHandlerContainer extends React.Component {
  paginationObserve = throttle(() => {
    const { loading, action, amountItems } = this.props;

    if (loading !== true && amountItems !== 0) {
      action();
    }
  }, OBSERVE_INTERVAL.PAGINATION_REQUEST);

  componentDidMount() {
    window.addEventListener("scroll", this.eventListener);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.eventListener);
  }

  eventListener = () => {
    return elementVisible(
      document.getElementById(PAGINATION_ID),
      this.paginationObserve
    );
  };

  render() {
    const { amountItems, indent, loading } = this.props;

    if (amountItems === 0) {
      return null;
    }

    return (
      <Container indent={loading} id={PAGINATION_ID}>
        {loading && <Progress />}
      </Container>
    );
  }
}

PaginationHandlerContainer.propTypes = {
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  amountPages: PropTypes.number,
  action: PropTypes.func,
  indent: PropTypes.bool,
};

export const PaginationHandler = PaginationHandlerContainer;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ indent }) => indent && `${spacing(3)} 0`};
`;
