import { axios } from "../utils/request";
import { API } from "../constants/api";
import { WITHDRAW_STATUS } from "./index";
import { getWithdrawInfo } from "./withdrawInfo";

const setFail = (message) => ({
  type: WITHDRAW_STATUS.LOAD_FAIL,
  message,
});

const setSuccess = () => ({
  type: WITHDRAW_STATUS.LOAD_SUCCESS,
});

const setLoading = () => ({
  type: WITHDRAW_STATUS.LOAD_PENDING,
});

export const changeWithdrawStatus = ({ withdrawId, status }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.patch(
        API.WITHDRAW_CHANGE_STATUS({ withdrawId }),
        { status }
      );
      dispatch(setSuccess(response.data));
      dispatch(getWithdrawInfo({ withdrawId }));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
