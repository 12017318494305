import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Text } from "../index";

export const ModalMenu = ({
  items,
  onSelect,
  onClose,
  open,
  activePath,
  anchorEl,
  pathConfig,
}) => {
  const navigate = useNavigate();

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {items.map(({ tid, id, path }) => (
        <MenuItem
          key={id}
          selected={activePath === path}
          component="a"
          onClick={(e) => {
            if (typeof path === "function") {
              navigate(path(pathConfig));
            } else {
              navigate(path);
            }
            onSelect();
          }}
        >
          <Text tid={tid} />
        </MenuItem>
      ))}
    </Menu>
  );
};

ModalMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  activePath: PropTypes.string,
  pathConfig: PropTypes.object,
  anchorEl: PropTypes.object,
};
