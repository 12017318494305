import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import lang from "../lang";

export const LANG = {
  RU: "RU",
};

const langList = Object.values(LANG);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: lang,
    fallbackLng: LANG.RU,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: langList,
    detection: {
      whitelist: langList,
      order: ["localStorage", "navigator"],
    },
  });

export { i18n };
