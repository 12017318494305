import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { List, ListItem, ListItemText } from "../../components/lists";
import { Text } from "../../components";
import { PaginationHandler } from "../handler-pagination";
import { colors, spacing } from "../../theme";
import { ROUTES } from "../../constants/routes";

export const PartnerApplicationList = ({
  list,
  pagination,
  paginationAction,
  updating,
}) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <List divider>
        {list.map(({ id, telegram, description }) => (
          <React.Fragment key={id}>
            <ListItem
              button
              onClick={() =>
                navigate(ROUTES.PARTNER_APPLICATION_INFO({ applicationId: id }))
              }
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <TextPrimary>
                      <Text
                        tid="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.TELEGRAM"
                        values={{ telegram }}
                      />
                    </TextPrimary>
                  </React.Fragment>
                }
                secondary={
                  <ListItemSecondaryContainer>
                    <TextSecondary>
                      <Text
                        tid="PARTNER_APPLICATION.PARTNER_APPLICATION_LIST.DESCRIPTION"
                        values={{ description }}
                      />
                    </TextSecondary>
                  </ListItemSecondaryContainer>
                }
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <PaginationHandler
        indent
        loading={updating}
        action={paginationAction}
        currentPage={pagination.currentPage}
        amountItems={pagination.amountItems}
      />
    </React.Fragment>
  );
};

PartnerApplicationList.propTypes = {
  list: PropTypes.array.isRequired,
  updating: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  paginationAction: PropTypes.func.isRequired,
};

const TextPrimary = styled.h3`
  line-height: 1.2;
  font-size: 16px;
  color: ${colors.textPrimary};
`;

const TextSecondary = styled.span`
  font-size: 14px;
  color: ${colors.textGray};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

const ListItemSecondaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: ${spacing(2)};
  overflow: hidden;
`;
