import { axios } from "../utils/request";
import { API } from "../constants/api";
import { DEPOSIT_INFO } from "./index";
import { performDepositInfo } from "../api/deposit";

const setFail = (message) => ({
  type: DEPOSIT_INFO.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: DEPOSIT_INFO.LOAD_SUCCESS,
  data: performDepositInfo(data),
});

const setLoading = () => ({
  type: DEPOSIT_INFO.LOAD_PENDING,
});

const setUpdating = () => ({
  type: DEPOSIT_INFO.UPDATE_PENDING,
});

const setUpdated = (data) => ({
  type: DEPOSIT_INFO.UPDATE_SUCCESS,
  data: performDepositInfo(data),
});

export const getDepositInfo = ({ depositId }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.DEPOSIT_INFO({ depositId }));
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateDepositInfo = ({ depositId }) => {
  return async (dispatch) => {
    dispatch(setUpdating());

    try {
      const response = await axios.get(API.DEPOSIT_INFO({ depositId }));
      dispatch(setUpdated(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
