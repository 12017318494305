import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { ResponsiveLayout, ContentLayout } from "../../components/layouts";
import { AUTH_MENU } from "../../constants/routes";

import { SkeletonMenu } from "../../components/skeletons";

import { FixedBar } from "./FixedBar";
import { Drawer } from "./Drawer";
import { MobileMenu } from "./MobileMenu";
import { sizes } from "../../theme";

export const MobileHeader = ({ activePath, loaded }) => {
  const [activeAppMenu, setActiveAppMenu] = React.useState(false);
  const [activeUserMenu, setActiveUserMenu] = React.useState(false);

  return (
    <React.Fragment>
      <FixedBar>
        <Container>
          <ContentLayout>
            <ResponsiveLayout>
              <Content>
                {loaded ? (
                  <React.Fragment>
                    <div>
                      <IconButton onClick={() => setActiveAppMenu(true)}>
                        <MenuIcon />
                      </IconButton>
                    </div>
                  </React.Fragment>
                ) : (
                  <SkeletonMenu />
                )}
              </Content>
            </ResponsiveLayout>
          </ContentLayout>
        </Container>
      </FixedBar>
      <Drawer open={activeAppMenu} onClose={() => setActiveAppMenu(false)}>
        <MobileMenu
          items={AUTH_MENU}
          activePath={activePath}
          onSelect={() => setActiveAppMenu(false)}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={activeUserMenu}
        onClose={() => setActiveUserMenu(false)}
      >
        <MobileMenu
          items={AUTH_MENU}
          activePath={activePath}
          onSelect={() => setActiveUserMenu(false)}
        />
      </Drawer>
    </React.Fragment>
  );
};

const Container = styled.div`
  background-color: #fff;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${sizes.headerHeight};
`;

MobileHeader.propTypes = {
  loaded: PropTypes.bool.isRequired,
  activePath: PropTypes.string.isRequired,
};
