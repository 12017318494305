import { axios } from "../utils/request";
import { API } from "../constants/api";
import { USER_INFO } from "./index";
import { convertChangeUserReferrerData, performUserInfo } from "../api/user";

const setFail = (message) => ({
  type: USER_INFO.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: USER_INFO.LOAD_SUCCESS,
  data: performUserInfo(data),
});

const setLoading = () => ({
  type: USER_INFO.LOAD_PENDING,
});

const setUpdating = () => ({
  type: USER_INFO.UPDATE_PENDING,
});

const setUpdated = (data) => ({
  type: USER_INFO.UPDATE_SUCCESS,
  data: performUserInfo(data),
});

export const setUserInfoData = (data) => ({
  type: USER_INFO.SET_DATA,
  data,
});

export const setUserReferrer = (data) => ({
  type: USER_INFO.SET_REFERRER,
  data,
});

export const getUserInfo = ({ userId }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.USER_INFO({ userId }));
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateUserInfo = ({ userId }) => {
  return async (dispatch) => {
    dispatch(setUpdating());

    try {
      const response = await axios.get(API.USER_INFO({ userId }));
      dispatch(setUpdated(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateUserReferrer = ({ userId, referrerId }) => {
  return async (dispatch) => {
    dispatch(setUpdating());

    const payload = convertChangeUserReferrerData({ referrerId });

    try {
      const response = await axios.patch(
        API.UPDATE_USER_REFERRER({ userId }),
        payload
      );
      dispatch(setUserReferrer(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
