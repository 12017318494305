import styled from "styled-components";

import TableRowUI from "@mui/material/TableRow";

export const TableRow = styled(TableRowUI)`
  && {
    &:not(:last-of-type) {
      border-bottom: 0px;
    }
  }
`;
