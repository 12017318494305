import { DEPOSIT, AUTH_USER, CURRENCY } from "../constants/fields";
import { getDateTime, performWithPagination } from "./index";

const performDepositListItemData = (raw) => ({
  id: raw[DEPOSIT.ID],
  currency: raw[DEPOSIT.CURRENCY]?.[CURRENCY.NAME],
  value: raw[DEPOSIT.VALUE],
  user: {
    email: raw[DEPOSIT.USER]?.[AUTH_USER.EMAIL],
    id: raw[DEPOSIT.USER]?.[AUTH_USER.ID],
  },
});

export const performDepositListData = performWithPagination((raw) =>
  raw.map(performDepositListItemData)
);

export const performDepositInfo = (raw) => ({
  id: raw[DEPOSIT.ID],
  currency: raw[DEPOSIT.CURRENCY]?.[CURRENCY.NAME],
  network: raw[DEPOSIT.NETWORK],
  value: raw[DEPOSIT.VALUE],
  createDate: getDateTime(raw[DEPOSIT.CREATE_DATE]),
  user: {
    email: raw[DEPOSIT.USER]?.[AUTH_USER.EMAIL],
    id: raw[DEPOSIT.USER]?.[AUTH_USER.ID],
  },
});
