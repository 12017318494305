import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { HeaderView } from "./View";
import { useLocation } from "react-router-dom";

export const HeaderContainer = ({ logged, user }) => {
  const location = useLocation();
  const isLoaded = () => logged !== null;

  const activePath = location.pathname;

  return (
    <HeaderView
      logged={logged}
      loaded={isLoaded()}
      user={user}
      activePath={activePath}
    />
  );
};

const mapStateToProps = ({ auth: { logged, user } }) => ({
  logged,
  user,
});

HeaderContainer.propTypes = {
  logged: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export const Header = compose(connect(mapStateToProps))(HeaderContainer);
