import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  changeUserRole,
  resetUserRoleChangeStatus,
} from "../../actions/userRoleChange";
import { setUserInfoData } from "../../actions/userInfo";
import {
  isRequestPending,
  isRequestSuccess,
  isRequestError,
  getErrorMessage,
  getData,
} from "../../utils/store";
import { UserRoleCheck } from "../user-role-check";
import { USER_ROLE } from "../../constants/static";

import { UserRoleChangeView } from "./View";

export const UserRoleChange = () => {
  const dispatch = useDispatch();

  const [roleValue, setRoleValue] = useState(USER_ROLE.USER);

  const {
    isDataLoading,
    isDataLoaded,
    isRequestLoading,
    isRequestLoaded,
    isError,
    errorMessage,
    role,
  } = useSelector(({ userRoleChange, userInfo }) => ({
    isDataLoading: isRequestPending(userInfo.data),
    isDataLoaded: isRequestSuccess(userInfo.data),
    isRequestLoading: isRequestPending(userRoleChange.request),
    isRequestLoaded: isRequestSuccess(userRoleChange.request),
    isError:
      isRequestError(userRoleChange.request) || isRequestError(userInfo.data),
    errorMessage:
      getErrorMessage(userRoleChange.request) || getErrorMessage(userInfo.data),
    role: getData(userInfo.data).role,
  }));

  const { userId } = useParams();

  const isFormDisabled = () => {
    return (
      roleValue === role || !roleValue || isDataLoading || isRequestLoading
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetUserRoleChangeStatus());
    };
  }, []);

  useEffect(() => {
    setRoleValue(role);
  }, [role]);

  const submitChangeUserRole = async (e) => {
    e.preventDefault();

    const data = await dispatch(
      changeUserRole({ userId }, { role: roleValue })
    );

    if (data) {
      const { role: updatedRole } = data;
      dispatch(setUserInfoData({ role: updatedRole }));
    }
  };

  const dataLoading = !isDataLoaded || isDataLoading;

  const { ADMIN, BLOCKED } = USER_ROLE;

  return (
    <React.Fragment>
      {role !== BLOCKED && (
        <UserRoleCheck roles={[ADMIN]}>
          <form onSubmit={submitChangeUserRole}>
            <UserRoleChangeView
              dataLoading={dataLoading}
              requestLoading={isRequestLoading}
              requestLoaded={isRequestLoaded}
              error={isError}
              errorMessage={errorMessage}
              disabled={isFormDisabled()}
              roleValue={roleValue}
              setRoleValue={setRoleValue}
            />
          </form>
        </UserRoleCheck>
      )}
    </React.Fragment>
  );
};
