import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAGINATION_OFFSET } from "../../constants/routes";
import { getStaffList, updateStaffList } from "../../actions/staffList";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isUpdatePending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

import { StaffListView } from "./View";

export const StaffList = () => {
  const { isLoading, isLoaded, staffData, isUpdating, isError, errorMessage } =
    useSelector(({ staffList: { data } }) => ({
      isLoading: isRequestPending(data),
      isLoaded: isRequestSuccess(data),
      isUpdating: isUpdatePending(data),
      isError: isRequestError(data),
      errorMessage: getErrorMessage(data),
      staffData: getData(data),
    }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        updateStaffList({
          skip: 0,
          take: PAGINATION_OFFSET.STAFF_LIST,
        })
      );
    } else {
      dispatch(
        getStaffList({
          skip: 0,
          take: PAGINATION_OFFSET.STAFF_LIST,
        })
      );
    }
  }, []);

  const paginationAction = () => {
    dispatch(
      updateStaffList({
        skip: staffData.pagination.skip + PAGINATION_OFFSET.SHOP_PRODUCT_LIST,
        take: PAGINATION_OFFSET.SHOP_PRODUCT_LIST,
      })
    );
  };

  const loading = isLoading && !isLoaded;

  return (
    <StaffListView
      loading={loading}
      loaded={isLoaded}
      error={isError}
      errorMessage={errorMessage}
      updating={isUpdating}
      staff={staffData}
      paginationAction={paginationAction}
    />
  );
};
