import React from "react";

import { Box } from "../../components/cards";
import { IndentLayout } from "../../components/layouts";
import { Loader, AlertSection } from "../../components";
import { SkeletonList } from "../../components/skeletons";
import { ListTitle } from "../../components/titles";
import { StatisticsBotsBalanceInfo } from "./Info";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <SkeletonList />
  </React.Fragment>
);

export const StatisticsBotsBalanceView = ({
  loading,
  loaded,
  error,
  errorMessage,
  data,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Box variant="outlined">
        <IndentLayout>
          {loading && (
            <React.Fragment>
              <ListTitle tid="STATISTICS.STATISTICS_BOTS_BALANCE.TITLE" />
              <LoaderComponent />
            </React.Fragment>
          )}
          {loaded && <StatisticsBotsBalanceInfo {...data} {...rest} />}
          <AlertSection error={error} errorMessage={errorMessage} />
        </IndentLayout>
      </Box>
    </React.Fragment>
  );
};
