/* eslint-disable import/no-cycle */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SkeletonMenu } from "../../components/skeletons";
import { ContentLayout } from "../../components/layouts";
import { ModalMenu } from "../../components/menus";
import { AUTH_MENU } from "../../constants/routes";
import { DesktopMenu } from "./DesktopMenu";
import { FixedBar } from "./FixedBar";
import { sizes } from "../../theme";

export const DesktopHeader = ({ activePath, loaded }) => {
  const [activeUserMenu, setActiveUserMenu] = React.useState(false);

  return (
    <React.Fragment>
      <FixedBar>
        <Container>
          <ContentLayout>
            <Content>
              {loaded ? (
                <DesktopMenu activePath={activePath} items={AUTH_MENU} />
              ) : (
                <SkeletonMenu />
              )}
            </Content>
          </ContentLayout>
        </Container>
      </FixedBar>
      <ModalMenu
        items={AUTH_MENU}
        open={activeUserMenu}
        activePath={activePath}
        onClose={() => setActiveUserMenu(false)}
        onSelect={() => setActiveUserMenu(false)}
      />
    </React.Fragment>
  );
};

const Container = styled.div`
  background-color: #fff;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${sizes.headerHeight};
`;

DesktopHeader.propTypes = {
  activePath: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
};
