import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
  getData,
} from "../../utils/store";
import { blockUser, resetUserBlockingStatus } from "../../actions/userBlocking";
import { setUserInfoData } from "../../actions/userInfo";
import { USER_ROLE } from "../../constants/static";

import { UserBlockingView } from "./View";

export const UserBlocking = () => {
  const {
    isDataLoading,
    isDataLoaded,
    isRequestLoading,
    isRequestLoaded,
    isError,
    errorMessage,
    isBlocked,
  } = useSelector(({ userBlocking, userInfo }) => ({
    isDataLoading: isRequestPending(userInfo.data),
    isDataLoaded: isRequestSuccess(userInfo.data),
    isRequestLoading: isRequestPending(userBlocking.request),
    isRequestLoaded: isRequestSuccess(userBlocking.request),
    isError:
      isRequestError(userInfo.data) || isRequestError(userBlocking.request),
    errorMessage:
      getErrorMessage(userInfo.data) || getErrorMessage(userBlocking.request),
    isBlocked: getData(userInfo.data).role === USER_ROLE.BLOCKED,
  }));

  const { userId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetUserBlockingStatus());
    };
  }, []);

  const submitBlockUser = async (e) => {
    e.preventDefault();

    const { blocked } = await dispatch(blockUser({ userId }));

    dispatch(
      setUserInfoData({ role: blocked ? USER_ROLE.BLOCKED : USER_ROLE.USER })
    );
  };

  const dataLoading = isDataLoading && !isDataLoaded;

  const isButtonDisabled = isRequestLoading || isDataLoading;

  return (
    <form onSubmit={submitBlockUser}>
      <UserBlockingView
        blocked={isBlocked}
        dataLoading={dataLoading}
        dataLoaded={isDataLoaded}
        requestLoaded={isRequestLoaded}
        requestLoading={isRequestLoading}
        error={isError}
        errorMessage={errorMessage}
        disabled={isButtonDisabled}
      />
    </form>
  );
};
