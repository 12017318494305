import React from "react";

import { Box } from "../../components/cards";
import { IndentLayout, SectionLayout } from "../../components/layouts";
import { Loader, AlertSection } from "../../components";
import { SkeletonList } from "../../components/skeletons";
import { ListTitle } from "../../components/titles";
import { StatisticsProfitInfo } from "./Info";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <SkeletonList />
  </React.Fragment>
);

export const StatisticsProfitView = ({
  loading,
  loaded,
  error,
  errorMessage,
  data,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Box variant="outlined">
        <IndentLayout>
          <SectionLayout>
            {loading && (
              <React.Fragment>
                <div>
                  <ListTitle tid="STATISTICS.STATISTICS_PROFIT.TITLE_CLASSIC" />
                  <LoaderComponent />
                </div>
                <div>
                  <ListTitle tid="STATISTICS.STATISTICS_PROFIT.TITLE_DOUBLE" />
                  <LoaderComponent />
                </div>
                <div>
                  <ListTitle tid="STATISTICS.STATISTICS_PROFIT.TITLE_TOTAL" />
                  <LoaderComponent />
                </div>
              </React.Fragment>
            )}
            {loaded && <StatisticsProfitInfo {...data} {...rest} />}
            <AlertSection error={error} errorMessage={errorMessage} />
          </SectionLayout>
        </IndentLayout>
      </Box>
    </React.Fragment>
  );
};
