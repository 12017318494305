import { STATISTICS } from "../constants/fields";

export const performStatisticsProfit = (raw) => ({
  classic: {
    turnover: raw[STATISTICS.CLASSIC][STATISTICS.TURNOVER],
    profit: raw[STATISTICS.CLASSIC][STATISTICS.PROFIT],
    profitPercent: raw[STATISTICS.CLASSIC][STATISTICS.PROFIT_PERCENT],
    currencies: raw[STATISTICS.CLASSIC][STATISTICS.CURRENCIES] || [],
  },
  double: {
    turnover: raw[STATISTICS.DOUBLE][STATISTICS.TURNOVER],
    profit: raw[STATISTICS.DOUBLE][STATISTICS.PROFIT],
    profitPercent: raw[STATISTICS.DOUBLE][STATISTICS.PROFIT_PERCENT],
    currencies: raw[STATISTICS.DOUBLE][STATISTICS.CURRENCIES] || [],
  },
  total: {
    turnover: raw[STATISTICS.TOTAL][STATISTICS.TURNOVER],
    profit: raw[STATISTICS.TOTAL][STATISTICS.PROFIT],
    currencies: raw[STATISTICS.TOTAL][STATISTICS.CURRENCIES] || [],
  },
});

export const performStatisticsUsersBalance = (raw) => ({
  total: raw[STATISTICS.TOTAL],
  currencies: raw[STATISTICS.CURRENCIES],
});

export const performStatisticsBotsBalance = (raw) => ({
  total: raw[STATISTICS.TOTAL],
  totalBots: raw[STATISTICS.TOTAL_BOTS],
  currencies: raw[STATISTICS.CURRENCIES],
});
