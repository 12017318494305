import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getDepositInfo, updateDepositInfo } from "../../actions/depositInfo";
import { DepositInfoView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const DepositInfo = () => {
  const { data, isLoading, isLoaded, isError, errorMessage, isUpdating } =
    useSelector(({ depositInfo: { data: depositInfo } }) => ({
      data: getData(depositInfo),
      isLoading: isRequestPending(depositInfo),
      isLoaded: isRequestSuccess(depositInfo),
      isError: isRequestError(depositInfo),
      errorMessage: getErrorMessage(depositInfo),
      isUpdating: depositInfo.updating,
    }));

  const { depositId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded && depositId === data._id) {
      dispatch(updateDepositInfo({ depositId }));
    } else {
      dispatch(getDepositInfo({ depositId }));
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <DepositInfoView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      isUpdating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
