import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { sizes } from "../../theme";

const IndentLayoutComponent = ({
  children,
  sizeWidth = "default",
  sizeHeight = sizeWidth,
  ...props
}) => {
  return (
    <Padding sizeWidth={sizeWidth} sizeHeight={sizeHeight} {...props}>
      {children}
    </Padding>
  );
};

export const IndentLayout = (props) => <IndentLayoutComponent {...props} />;

IndentLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  sizeWidth: PropTypes.string,
  sizeHeight: PropTypes.string,
};

export const Padding = styled.div`
  padding: 16px;
  @media screen and (max-width: 1199px) {
    padding: 15px;
    /* max-width: 400px; */
    margin: 0 auto;
  }
`;
