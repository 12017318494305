import { axios } from "../utils/request";

import { API } from "../constants/api";
import { USER_LIST } from "./index";
import { performUserListData } from "../api/user";

const setFail = (message) => ({
  type: USER_LIST.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: USER_LIST.LOAD_SUCCESS,
  data: performUserListData(data),
});

const setLoading = () => ({
  type: USER_LIST.LOAD_PENDING,
});

const updateSuccess = (data) => ({
  type: USER_LIST.UPDATE_SUCCESS,
  data: performUserListData(data),
});

const updatePending = () => ({
  type: USER_LIST.UPDATE_PENDING,
});

export const resetStore = () => ({
  type: USER_LIST.RESET_STORE,
});

export const changeFindValue = (value) => ({
  type: USER_LIST.CHANGE_FIND_VALUE,
  value,
});

export const getUserList = ({ skip, take, findValue }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const { data } = await axios.get(
        API.USER_LIST({ skip, take, findValue })
      );
      dispatch(setSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateUserList = ({ skip, take, findValue }) => {
  return async (dispatch) => {
    dispatch(updatePending());
    try {
      const { data } = await axios.get(
        API.USER_LIST({ skip, take, findValue })
      );
      dispatch(updateSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
