import { axios } from "../utils/request";
import { API } from "../constants/api";
import { PROJECT_SETTINGS } from "./index";
import { performProjectSettingsInfo } from "../api/project-settings";

const setFail = (message) => ({
  type: PROJECT_SETTINGS.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: PROJECT_SETTINGS.LOAD_SUCCESS,
  data: performProjectSettingsInfo(data),
});

const setLoading = () => ({
  type: PROJECT_SETTINGS.LOAD_PENDING,
});

const setUpdating = () => ({
  type: PROJECT_SETTINGS.UPDATE_PENDING,
});

const setUpdated = (data) => ({
  type: PROJECT_SETTINGS.UPDATE_SUCCESS,
  data: performProjectSettingsInfo(data),
});

export const getProjectSettingsData = () => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.PROJECT_SETTINGS_DATA());
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateProjectSettingsData = () => {
  return async (dispatch) => {
    dispatch(setUpdating());

    try {
      const response = await axios.get(API.PROJECT_SETTINGS_DATA());
      dispatch(setUpdated(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
