import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Text } from "../index";

export const ProfileLayout = ({ children, title }) => (
  <Wrapper>
    <Container>
      {title && (
        <TitleContainer>
          <TitleInner>
            <MainTitle>
              <Text tid={title} />
            </MainTitle>
          </TitleInner>
        </TitleContainer>
      )}
      {children}
    </Container>
  </Wrapper>
);

ProfileLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

const Wrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 76px);
  background-color: #f6f6f6;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: calc(100% - 220px);
  max-width: 1700px;
  background-color: #f6f6f6;
  padding: 75px 145px;

  @media screen and (max-width: 1800px) {
    padding: 50px 30px;
  }

  @media (max-width: 930px) {
    width: 100%;
    padding: 30px 20px;
  }

  @media (max-width: 600px) {
    padding: 20px 10px;
  }
`;
const TitleContainer = styled.div`
  max-width: 1360px;
  margin-bottom: 25px;
`;
const TitleInner = styled.div`
  width: 100%;
  max-width: 785px;
`;
const MainTitle = styled.div`
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  color: #222531;
  @media (max-width: 910px) {
    font-size: 20px;
  }
`;
