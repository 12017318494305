import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";
import { changePartnerApplicationStatus } from "../../actions/partnerApplicationStatus";
import { PartnerApplicationStatusView } from "./View";
import { PARTNER_APPLICATION_STATUS } from "../../constants/static";
import { getPartnerApplicationStatus } from "../../api";

export const PartnerApplicationStatus = () => {
  const dispatch = useDispatch();

  const [partnerPercent, setPartnerPercent] = useState("");

  const {
    data,
    isDataLoaded,
    isDataLoading,
    isLoading,
    isError,
    errorMessage,
  } = useSelector(
    ({
      partnerApplicationInfo: { data: partnerApplicationInfo },
      partnerApplicationStatus: { request: partnerApplicationStatus },
    }) => ({
      data: getData(partnerApplicationInfo),
      isDataLoaded: isRequestSuccess(partnerApplicationInfo),
      isDataLoading: isRequestPending(partnerApplicationInfo),
      isLoading: isRequestPending(partnerApplicationStatus),
      isError: isRequestError(partnerApplicationStatus),
      errorMessage: getErrorMessage(partnerApplicationStatus),
    })
  );

  const { applicationId } = useParams();

  const handleApprove = () => {
    dispatch(
      changePartnerApplicationStatus({
        applicationId,
        partnerPercent,
        status: PARTNER_APPLICATION_STATUS.APPROVED,
      })
    );
  };

  const handleReject = () => {
    dispatch(
      changePartnerApplicationStatus({
        applicationId,
        status: PARTNER_APPLICATION_STATUS.REJECTED,
      })
    );
  };

  if (
    data.status !==
    getPartnerApplicationStatus(PARTNER_APPLICATION_STATUS.PENDING)
  ) {
    return null;
  }

  const getLoading = () => {
    return !isDataLoaded || isDataLoading || isLoading;
  };

  const handleChangePartnerPercent = async (e) => {
    const percent = e.target.value;

    if (isNaN(Number(percent))) e.preventDefault();
    else {
      setPartnerPercent(percent);
    }
    return null;
  };

  const isApproveDisabled =
    !partnerPercent || partnerPercent <= 0 || partnerPercent > 5;

  return (
    <PartnerApplicationStatusView
      dataLoaded={isDataLoaded}
      loading={getLoading()}
      isApproveDisabled={isApproveDisabled}
      handleApprove={handleApprove}
      handleReject={handleReject}
      error={isError}
      errorMessage={errorMessage}
      partnerPercent={partnerPercent}
      setPartnerPercent={handleChangePartnerPercent}
    />
  );
};
