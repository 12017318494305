import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { List, ListItem, ListItemText } from "../../components/lists";
import { PaginationHandler } from "../handler-pagination";
import { colors } from "../../theme";
import { ROUTES } from "../../constants/routes";
import { Text } from "../../components";

export const StaffList = ({ list, pagination, paginationAction, updating }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <List divider>
        {list.map(({ id, email, role }) => (
          <React.Fragment key={id}>
            <ListItem
              button
              onClick={() => navigate(ROUTES.USER_INFO({ userId: id }))}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Login>
                      <Text tid="USER.STAFF_LIST.EMAIL" values={{ email }} />
                    </Login>
                    <Role>
                      <Text tid="USER.STAFF_LIST.ROLE" values={{ role }} />
                    </Role>
                  </React.Fragment>
                }
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <PaginationHandler
        indent
        loading={updating}
        action={paginationAction}
        currentPage={pagination.currentPage}
        amountItems={pagination.amountItems}
      />
    </React.Fragment>
  );
};

StaffList.propTypes = {
  list: PropTypes.array.isRequired,
  updating: PropTypes.bool,
  pagination: PropTypes.object.isRequired,
  paginationAction: PropTypes.func.isRequired,
};

const Login = styled.h3`
  line-height: 1.2;
  font-size: 16px;
  color: ${colors.textPrimary};
`;

const Role = styled.span`
  font-size: 14px;
  color: ${colors.textGray};
`;
