export const WITHDRAW_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const WITHDRAW_TYPE = {
  DEFAULT: "DEFAULT",
  PARTNER: "PARTNER",
};

export const DEPOSIT_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const PARTNER_APPLICATION_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const USER_ROLE = {
  BLOCKED: "BLOCKED",
  NOT_VERIFIED: "NOT_VERIFIED",
  USER: "USER",
  PARTNER: "PARTNER",
  SUPPORT: "SUPPORT",
  ADMIN: "ADMIN",
};

export const SELECT_ROLE_VALUES = [
  {
    id: 1,
    value: USER_ROLE.USER,
    tid: "STATIC.USER_ROLE_TYPE.USER",
  },
  {
    id: 2,
    value: USER_ROLE.PARTNER,
    tid: "STATIC.USER_ROLE_TYPE.PARTNER",
  },
  {
    id: 3,
    value: USER_ROLE.SUPPORT,
    tid: "STATIC.USER_ROLE_TYPE.SUPPORT",
  },
  {
    id: 4,
    value: USER_ROLE.ADMIN,
    tid: "STATIC.USER_ROLE_TYPE.ADMIN",
  },
];
