import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { Text, Link } from "../../components";
import { spacing, colors } from "../../theme";
import { logOut } from "../../actions/login";

export const DesktopMenu = ({ items, activePath }) => {
  return (
    <Container>
      <Links>
        {items.map(({ tid, path, id }) => {
          const active = activePath.includes(path);

          return (
            <React.Fragment key={id}>
              <Item underline="none" href={path} active={active}>
                <Text tid={tid} />
              </Item>
            </React.Fragment>
          );
        })}
      </Links>
      <Item underline="none" onClick={logOut}>
        <Text tid="NAVIGATION.PATH.LOGOUT" />
      </Item>
    </Container>
  );
};

const Links = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 16px;
`;

const Container = styled.div`
  justify-content: space-between;
  padding-right: 24px;
  display: flex;
  width: 100%;
`;

const Item = styled(Link)`
  && {
    padding: ${spacing(4)} 0;
    font-size: 14px !important;
    ${(p) =>
      p.active
        ? css`
            color: ${colors.primary} !important;
            &&:hover {
              color: ${colors.primary} !important;
            }
          `
        : ""}
  }
`;

DesktopMenu.propTypes = {
  items: PropTypes.bool.isRequired,
  activePath: PropTypes.string.isRequired,
};
