import { WITHDRAW, AUTH_USER, CURRENCY } from "../constants/fields";
import {
  getDateTime,
  getWithdrawStatus,
  getWithdrawType,
  performWithPagination,
} from "./index";

const performWithdrawListItemData = (raw) => ({
  id: raw[WITHDRAW.ID],
  currency: raw[WITHDRAW.CURRENCY]?.[CURRENCY.NAME],
  value: raw[WITHDRAW.VALUE],
  status: raw[WITHDRAW.STATUS],
  user: {
    email: raw[WITHDRAW.USER]?.[AUTH_USER.EMAIL],
    id: raw[WITHDRAW.USER]?.[AUTH_USER.ID],
  },
});

export const performWithdrawListData = performWithPagination((raw) =>
  raw.map(performWithdrawListItemData)
);

export const performWithdrawInfo = (raw) => ({
  id: raw[WITHDRAW.ID],
  currency: raw[WITHDRAW.CURRENCY]?.[CURRENCY.NAME],
  network: raw[WITHDRAW.NETWORK],
  address: raw[WITHDRAW.ADDRESS],
  value: raw[WITHDRAW.VALUE],
  status: getWithdrawStatus(raw[WITHDRAW.STATUS]),
  createDate: getDateTime(raw[WITHDRAW.CREATE_DATE]),
  type: getWithdrawType(raw[WITHDRAW.TYPE]),
  user: {
    email: raw[WITHDRAW.USER]?.[AUTH_USER.EMAIL],
    id: raw[WITHDRAW.USER]?.[AUTH_USER.ID],
  },
});
