import React from "react";

import { Loader, AlertSection, Divider, Text } from "../../components";
import { Box } from "../../components/cards";
import { SkeletonDataList } from "../../components/skeletons";
import { DepositList } from "./List";
import { ListActionTitle } from "../../components/titles";
import { Tabs, Tab } from "../../components/tabs";
import { DEPOSIT_STATUS } from "../../constants/static";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <Box variant="outlined">
      <SkeletonDataList size="big" />
    </Box>
  </React.Fragment>
);

export const DepositListView = ({
  loading,
  loaded,
  error,
  errorMessage,
  deposits,
  paginationAction,
  updating,
  currentStatus,
  handleChangeCurrentStatus,
}) => {
  const isEmpty = loaded && deposits.list.length === 0;

  return (
    <div>
      {updating && <Loader />}
      <Box variant="outlined">
        <ListActionTitle divider={!isEmpty} tid="DEPOSIT.DEPOSIT_LIST.TITLE" />

        <Tabs value={currentStatus} onChange={handleChangeCurrentStatus}>
          <Tab
            value={DEPOSIT_STATUS.PENDING}
            disabled={loading || updating}
            label={<Text tid="DEPOSIT.DEPOSIT_LIST.STATUS_PENDING" />}
          />
          <Tab
            value={DEPOSIT_STATUS.APPROVED}
            disabled={loading || updating}
            label={<Text tid="DEPOSIT.DEPOSIT_LIST.STATUS_APPROVED" />}
          />
          <Tab
            value={DEPOSIT_STATUS.REJECTED}
            disabled={loading || updating}
            label={<Text tid="DEPOSIT.DEPOSIT_LIST.STATUS_REJECTED" />}
          />
        </Tabs>
        <Divider />
        {loading && <LoaderComponent />}
        {loaded && deposits.list.length !== 0 && (
          <DepositList
            paginationAction={paginationAction}
            pagination={deposits.pagination}
            updating={updating}
            list={deposits.list}
          />
        )}
      </Box>
      <AlertSection
        error={error}
        errorMessage={errorMessage}
        info={isEmpty}
        infoMessage="DEPOSIT.DEPOSIT_LIST.EMPTY_LIST"
      />
    </div>
  );
};
