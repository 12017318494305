import { axios } from "../utils/request";
import { API } from "../constants/api";
import { PROJECT_SETTINGS_BOTS } from "./index";

const setFail = (message) => ({
  type: PROJECT_SETTINGS_BOTS.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: PROJECT_SETTINGS_BOTS.LOAD_SUCCESS,
  data,
});

const setLoading = () => ({
  type: PROJECT_SETTINGS_BOTS.LOAD_PENDING,
});

export const updateProjectSettingsBots = (payload) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.post(API.PROJECT_SETTINGS_BOTS(), payload);
      dispatch(setSuccess(response.data));
    } catch ({ response: { data } }) {
      dispatch(setFail(data.message));
    }
  };
};
