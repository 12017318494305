/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";
import { JobsView } from "./View";

export const Jobs = () => {
  const [spacePressed, setSpacePressed] = useState(true);
  const [jobsStorage, setJobStorage] = useState({});
  const [timer, setTimer] = useState(null);

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
      setSpacePressed((prev) => !prev);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    if (timer) clearInterval(timer);

    if (!spacePressed) {
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }

    const intervalId = setInterval(async () => {
      try {
        const { data: jobs } = await axios.get(API.JOBS_STORAGE());
        setJobStorage(jobs);
      } catch (error) {
        console.error(error);
      }
    }, 300);

    setTimer(intervalId);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearInterval(intervalId);
    };
  }, [spacePressed]);

  return <JobsView jobsStorage={jobsStorage} spacePressed={spacePressed} />;
};
