import React from "react";
import styled from "styled-components";

import { ListTitle } from "../../components/titles";
import { ListGrid } from "../../components/grids";
import { Loader, Text } from "../../components";
import { colors, sizes } from "../../theme";

export const StatisticsProfitInfo = (props) => {
  const { classic, double, total, isUpdating } = props;

  return (
    <React.Fragment>
      <div>
        <ListTitle tid="STATISTICS.STATISTICS_PROFIT.TITLE_CLASSIC" />
        <ListGrid>
          {isUpdating ? <Loader /> : null}
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.TURNOVER" />
              :&nbsp;
            </Heading>
            <Info>{classic.turnover.toFixed(2)}$</Info>
          </Field>
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT" />
              :&nbsp;
            </Heading>
            <Info>{classic.profit.toFixed(2)}$</Info>
          </Field>
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT_PERCENT" />
              :&nbsp;
            </Heading>
            <Info>{classic.profitPercent}%</Info>
          </Field>
          {Object.keys(classic.currencies).map((currency) => {
            const { turnover, profit } = classic.currencies[currency];

            return (
              <Field>
                <Heading>
                  {currency.toUpperCase()}
                  :&nbsp;
                </Heading>
                {/* <Info>{`${turnover.toFixed(
                  8
                )} ${currency.toUpperCase()} (${sumUsdt.toFixed(2)}$)`}</Info> */}
                <Info>
                  <Text tid="STATISTICS.STATISTICS_PROFIT.TURNOVER" />:{" "}
                  {turnover.toFixed(8)} {currency.toUpperCase()}
                  &nbsp;|&nbsp;
                  <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT" />:{" "}
                  {profit.toFixed(8)} {currency.toUpperCase()}
                </Info>
              </Field>
            );
          })}
        </ListGrid>
      </div>
      <div>
        <ListTitle tid="STATISTICS.STATISTICS_PROFIT.TITLE_DOUBLE" />
        <ListGrid>
          {isUpdating ? <Loader /> : null}
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.TURNOVER" />
              :&nbsp;
            </Heading>
            <Info>{double.turnover.toFixed(2)}$</Info>
          </Field>
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT" />
              :&nbsp;
            </Heading>
            <Info>{double.profit.toFixed(2)}$</Info>
          </Field>
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT_PERCENT" />
              :&nbsp;
            </Heading>
            <Info>{double.profitPercent}%</Info>
          </Field>
          {Object.keys(double.currencies).map((currency) => {
            const { turnover, profit } = double.currencies[currency];

            return (
              <Field>
                <Heading>
                  {currency.toUpperCase()}
                  :&nbsp;
                </Heading>
                {/* <Info>{`${turnover.toFixed(
                  8
                )} ${currency.toUpperCase()} (${sumUsdt.toFixed(2)}$)`}</Info> */}
                <Info>
                  <Text tid="STATISTICS.STATISTICS_PROFIT.TURNOVER" />:{" "}
                  {turnover.toFixed(8)} {currency.toUpperCase()}
                  &nbsp;|&nbsp;
                  <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT" />:{" "}
                  {profit.toFixed(8)} {currency.toUpperCase()}
                </Info>
              </Field>
            );
          })}
        </ListGrid>
      </div>
      <div>
        <ListTitle tid="STATISTICS.STATISTICS_PROFIT.TITLE_TOTAL" />
        <ListGrid>
          {isUpdating ? <Loader /> : null}
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.TURNOVER" />
              :&nbsp;
            </Heading>
            <Info>{total.turnover.toFixed(2)}$</Info>
          </Field>
          <Field>
            <Heading>
              <Text tid="STATISTICS.STATISTICS_PROFIT.PROFIT" />
              :&nbsp;
            </Heading>
            <Info>{total.profit.toFixed(2)}$</Info>
          </Field>
        </ListGrid>
      </div>
    </React.Fragment>
  );
};

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  font-weight: 600;
  color: ${colors.textPrimary};
`;

const Info = styled.span`
  font-size: ${sizes.font.default};
  line-height: 140%;
  color: ${colors.textPrimary};
`;
