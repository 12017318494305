import React from "react";
import styled from "styled-components";

import { Box } from "../../components/cards";
import { IndentLayout } from "../../components/layouts";
import { Text, AlertSection, Loader } from "../../components";
import { ListTitle } from "../../components/titles";
import { ButtonPrimary } from "../../components/buttons";
import { TextField } from "../../components/fields";
import { FieldGrid } from "../../components/grids";

export const StatisticsUsersAddBalanceView = ({
  loading,
  isButtonDisabled,
  handleSubmit,
  error,
  errorMessage,
  users,
  handleChangeUsers,
  isLoaded,
}) => {
  return (
    <React.Fragment>
      {loading && <Loader />}
      <Box variant="outlined">
        <IndentLayout>
          <ListTitle tid="BALANCES.ADD_USERS_BALANCE.TITLE" offset={3} />
          <p style={{ marginBottom: "16px" }}>
            <Text tid="BALANCES.ADD_USERS_BALANCE.HINT" />
          </p>
          <FieldGrid double>
            <TextField
              value={users}
              onChange={handleChangeUsers}
              label={<Text tid="BALANCES.ADD_USERS_BALANCE.USERS" />}
              required
              type="text"
            />
          </FieldGrid>
          <ButtonContainer>
            <ButtonPrimary
              onClick={handleSubmit}
              variant="outlined"
              disabled={isButtonDisabled || loading}
            >
              <Text tid="BALANCES.ADD_USERS_BALANCE.BUTTON" />
            </ButtonPrimary>
          </ButtonContainer>
          <AlertSection
            success={isLoaded}
            successMessage="BALANCES.ADD_USERS_BALANCE.ALERT_SUCCESS"
          />
        </IndentLayout>
      </Box>
      <AlertSection error={error} errorMessage={errorMessage} offset={0} />
    </React.Fragment>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;
