import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getUserInfo,
  updateUserInfo,
  updateUserReferrer,
} from "../../actions/userInfo";
import { UserInfoView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const UserInfo = () => {
  const {
    users,
    data,
    isLoading,
    isLoaded,
    isError,
    errorMessage,
    isUpdating,
  } = useSelector(({ userInfo: { data: userInfo }, userList }) => ({
    users: getData(userList.data)?.list || [],
    data: getData(userInfo),
    isLoading: isRequestPending(userInfo),
    isLoaded: isRequestSuccess(userInfo),
    isError: isRequestError(userInfo),
    errorMessage: getErrorMessage(userInfo),
    isUpdating: userInfo.updating,
  }));

  const { userId } = useParams();

  const mappedUsers = React.useMemo(
    () =>
      users
        .filter((u) => u.id !== parseInt(userId, 10))
        .map((user) => ({
          id: user.id,
          value: user.id.toString(),
          tid: user.email,
        })),
    [users, userId]
  );
  const [referrerId, setReferrerId] = React.useState();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getUserList({ skip: 0, take: 0 }));

    if (isLoaded && userId === data._id) {
      dispatch(updateUserInfo({ userId }));
    } else {
      dispatch(getUserInfo({ userId }));
    }
  }, []);

  const updateReferrer = async () => {
    await dispatch(updateUserReferrer({ userId, referrerId }));
    setReferrerId();
  };

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <UserInfoView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      isUpdating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
      users={mappedUsers}
      referrerId={referrerId}
      setReferrerId={setReferrerId}
      updateReferrer={updateReferrer}
    />
  );
};
