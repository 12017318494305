import React from "react";
import styled from "styled-components";

import { Loader, AlertSection, Text, Divider } from "../../components";
import { Box } from "../../components/cards";
import { SkeletonDataList } from "../../components/skeletons";
import { WithdrawList } from "./List";
import { ListActionTitle } from "../../components/titles";
import { Tabs, Tab } from "../../components/tabs";
import { WITHDRAW_STATUS, WITHDRAW_TYPE } from "../../constants/static";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <Box variant="outlined">
      <SkeletonDataList size="big" />
    </Box>
  </React.Fragment>
);

export const WithdrawView = ({
  loading,
  loaded,
  error,
  errorMessage,
  withdraws,
  paginationAction,
  updating,
  currentStatus,
  handleChangeCurrentStatus,
  currentType,
  handleChangeCurrentType,
}) => {
  const isEmpty = loaded && withdraws.list.length === 0;

  return (
    <div>
      {updating && <Loader />}
      <Box variant="outlined">
        <ListActionTitle tid="WITHDRAW.WITHDRAW_LIST.TITLE" />
        <TabsContainer>
          <Tabs value={currentStatus} onChange={handleChangeCurrentStatus}>
            <Tab
              value={WITHDRAW_STATUS.PENDING}
              disabled={loading || updating}
              label={<Text tid="WITHDRAW.WITHDRAW_LIST.STATUS_PENDING" />}
            />
            <Tab
              value={WITHDRAW_STATUS.APPROVED}
              disabled={loading || updating}
              label={<Text tid="WITHDRAW.WITHDRAW_LIST.STATUS_APPROVED" />}
            />
            <Tab
              value={WITHDRAW_STATUS.REJECTED}
              disabled={loading || updating}
              label={<Text tid="WITHDRAW.WITHDRAW_LIST.STATUS_REJECTED" />}
            />
          </Tabs>
          <Tabs value={currentType} onChange={handleChangeCurrentType}>
            <Tab
              value={WITHDRAW_TYPE.DEFAULT}
              disabled={loading || updating}
              label={<Text tid="WITHDRAW.WITHDRAW_LIST.TYPE_DEFAULT" />}
            />
            <Tab
              value={WITHDRAW_TYPE.PARTNER}
              disabled={loading || updating}
              label={<Text tid="WITHDRAW.WITHDRAW_LIST.TYPE_PARTNER" />}
            />
          </Tabs>
        </TabsContainer>
        <Divider />

        {loading && <LoaderComponent />}
        {loaded && withdraws.list.length !== 0 && (
          <WithdrawList
            paginationAction={paginationAction}
            pagination={withdraws.pagination}
            updating={updating}
            list={withdraws.list}
          />
        )}
      </Box>
      <AlertSection
        error={error}
        errorMessage={errorMessage}
        info={isEmpty}
        infoMessage="WITHDRAW.WITHDRAW_LIST.EMPTY_LIST"
      />
    </div>
  );
};

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
