import React from "react";
import PropTypes from "prop-types";

import { Loader, AlertSection } from "../../components";
import { Box } from "../../components/cards";
import { SkeletonDataList } from "../../components/skeletons";
import { UserList } from "./List";

const LoaderComponent = () => (
  <React.Fragment>
    <Loader />
    <Box variant="outlined">
      <SkeletonDataList size="big" />
    </Box>
  </React.Fragment>
);

export const UserListView = ({
  loading,
  loaded,
  error,
  errorMessage,
  users,
  paginationAction,
  updating,
}) => {
  const isEmpty = loaded && users.list.length === 0;

  return (
    <div>
      {loading && <LoaderComponent />}
      {updating && <Loader />}
      {loaded && users.list.length !== 0 && (
        <Box variant="outlined">
          <UserList
            paginationAction={paginationAction}
            pagination={users.pagination}
            updating={updating}
            list={users.list}
          />
        </Box>
      )}

      <AlertSection
        error={error}
        errorMessage={errorMessage}
        offset={0}
        info={isEmpty}
        infoMessage="USER.USER_LIST.EMPTY_LIST"
      />
    </div>
  );
};

UserListView.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  users: PropTypes.object,
  updating: PropTypes.bool,
  paginationAction: PropTypes.func,
};
