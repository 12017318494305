import { AUTH_USER, USER_STATISTICS } from "../constants/fields";
import { performWithPagination, getUserRole, getDateTime } from "./index";
import { USER_ROLE } from "../constants/static";

const performUserListItemData = (raw) => ({
  id: raw[AUTH_USER.ID],
  email: raw[AUTH_USER.EMAIL],
  nickname: raw[AUTH_USER.NICKNAME],
});

export const performStaffListItemData = (raw) => ({
  id: raw[AUTH_USER.ID],
  role: getUserRole(raw[AUTH_USER.ROLE]),
  email: raw[AUTH_USER.EMAIL],
});

export const performStaffListData = performWithPagination((raw) =>
  raw.map(performStaffListItemData)
);

export const performUserListData = performWithPagination((raw) =>
  raw.map(performUserListItemData)
);

export const performUserInfo = (raw) => ({
  id: raw[AUTH_USER.ID],
  email: raw[AUTH_USER.EMAIL],
  nickname: raw[AUTH_USER.NICKNAME],
  role: raw[AUTH_USER.ROLE],
  createDate: getDateTime(raw[AUTH_USER.CREATE_DATE]),
  referrer: {
    id: raw[AUTH_USER.REFERRER]?.[AUTH_USER.ID],
    email: raw[AUTH_USER.REFERRER]?.[AUTH_USER.EMAIL],
  },
});

export const performUserStatisticsInfo = (raw) => ({
  totalBets: raw[USER_STATISTICS.TOTAL_BETS],
  totalGames: raw[USER_STATISTICS.TOTAL_GAMES],
  turnover: `${raw[USER_STATISTICS.TURNOVER]}$`,
});

export const performUserBlockingData = (raw) => ({
  blocked: raw[AUTH_USER.ROLE] === USER_ROLE.BLOCKED,
});

export const performUserRoleData = (raw) => ({
  role: raw[AUTH_USER.ROLE],
});

export const convertChangeUserRoleData = ({ role }) => ({
  [AUTH_USER.ROLE]: role,
});

export const convertChangeUserReferrerData = ({ referrerId }) => ({
  [AUTH_USER.REFERRER_ID]: parseInt(referrerId, 10),
});
