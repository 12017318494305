import { axios } from "../utils/request";

import { API } from "../constants/api";
import { USER_BLOCKING } from "./index";
import { performUserBlockingData } from "../api/user";

const setFail = (message) => ({
  type: USER_BLOCKING.REQUEST_FAIL,
  message,
});

const setBlockSuccess = (data) => ({
  type: USER_BLOCKING.REQUEST_SUCCESS,
  data,
});

const setBlockLoading = () => ({
  type: USER_BLOCKING.REQUEST_PENDING,
});

export const resetUserBlockingStatus = () => ({
  type: USER_BLOCKING.RESET_STATUS,
});

export const blockUser = ({ userId }) => {
  return async (dispatch) => {
    dispatch(setBlockLoading());

    try {
      const { data } = await axios.patch(API.USER_BLOCK({ userId }));
      dispatch(setBlockSuccess());
      return performUserBlockingData(data);
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
