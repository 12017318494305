import React from "react";
import styled from "styled-components";

import { Box } from "../../components/cards";
import { IndentLayout } from "../../components/layouts";
import { Text, AlertSection, Loader } from "../../components";
import { ListTitle } from "../../components/titles";
import { ButtonDanger, ButtonPrimary } from "../../components/buttons";

export const WithdrawStatusView = ({
  dataLoaded,
  handleApprove,
  handleReject,
  error,
  errorMessage,
  loading,
}) => {
  return (
    <React.Fragment>
      {dataLoaded && (
        <React.Fragment>
          {loading && <Loader />}
          <Box variant="outlined">
            <IndentLayout>
              <ListTitle tid="WITHDRAW.WITHDRAW_STATUS.TITLE" />
              <ButtonContainer>
                <ButtonPrimary
                  onClick={handleApprove}
                  variant="outlined"
                  disabled={loading}
                >
                  <Text tid="WITHDRAW.WITHDRAW_STATUS.BUTTON_APPROVE" />
                </ButtonPrimary>
                <ButtonDanger
                  onClick={handleReject}
                  variant="outlined"
                  disabled={loading}
                >
                  <Text tid="WITHDRAW.WITHDRAW_STATUS.BUTTON_REJECT" />
                </ButtonDanger>
              </ButtonContainer>
            </IndentLayout>
          </Box>
          <AlertSection error={error} errorMessage={errorMessage} offset={0} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;
