import React from "react";
import styled from "styled-components";

import { AUTH_USER } from "../../constants/fields";
import { TextField, TextFieldPassword } from "../../components/fields";
import { Text, Loader, AlertSection } from "../../components";
import { Box } from "../../components/cards";
import { IndentLayout } from "../../components/layouts";
import { ButtonPrimary } from "../../components/buttons";
import { FormTitle } from "../../components/titles";
import { FieldGrid } from "../../components/grids";

export const LogInView = ({
  fields,
  changeField,
  onSubmit,
  disabled,
  loading,
  error,
  errorMessage,
}) => {
  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <Box variant="outlined">
          <IndentLayout>
            <FormTitle tid="LOGIN.FORM.TITLE" />
            <FieldGrid>
              <TextField
                value={fields[AUTH_USER.EMAIL]}
                onChange={(e) => changeField(AUTH_USER.EMAIL, e.target.value)}
                label={<Text tid="LOGIN.FORM.EMAIL" />}
                required
              />
              <TextFieldPassword
                value={fields[AUTH_USER.PASSWORD]}
                onChange={(e) =>
                  changeField(AUTH_USER.PASSWORD, e.target.value)
                }
                label={<Text tid="LOGIN.FORM.PASSWORD" />}
                required
              />
            </FieldGrid>
            <ButtonSubmit
              size="large"
              variant="outlined"
              color="primary"
              disabled={disabled}
              type="submit"
            >
              <Text tid="LOGIN.FORM.BUTTON_SUBMIT" />
            </ButtonSubmit>
          </IndentLayout>
          {loading && <Loader />}
        </Box>
        <AlertSection error={error} errorMessage={errorMessage} />
      </form>
    </React.Fragment>
  );
};

const ButtonSubmit = styled(ButtonPrimary)`
  width: 100%;
`;
