import { USER_LIST } from "../actions";
import {
  initRequestWithDataState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
  setUpdateSuccess,
  setUpdatePending,
} from "../utils/store";

const initialState = {
  data: initRequestWithDataState(),
  findValue: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LIST.LOAD_SUCCESS:
      return {
        ...state,
        data: setRequestSuccess(state.data, action.data),
      };
    case USER_LIST.LOAD_PENDING:
      return {
        ...state,
        data: setRequestPending(state.data),
        findValue: "",
      };
    case USER_LIST.LOAD_FAIL:
      return {
        ...state,
        data: setRequestError(state.data, action.message),
      };
    case USER_LIST.UPDATE_PENDING:
      return {
        ...state,
        data: setUpdatePending(state.data),
      };
    case USER_LIST.UPDATE_SUCCESS:
      return {
        ...state,
        data: setUpdateSuccess(state.data, action.data),
      };
    case USER_LIST.RESET_STORE:
      return {
        ...state,
        data: initRequestWithDataState(),
      };
    case USER_LIST.CHANGE_FIND_VALUE:
      return {
        ...state,
        findValue: action.value,
      };
    default:
      return state;
  }
};
