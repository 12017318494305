import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getPartnerApplicationInfo,
  updatePartnerApplicationInfo,
} from "../../actions/partnerApplicationInfo";
import { PartnerApplicationView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const PartnerApplicationInfo = () => {
  const { data, isLoading, isLoaded, isError, errorMessage, isUpdating } =
    useSelector(
      ({ partnerApplicationInfo: { data: partnerApplicationInfo } }) => ({
        data: getData(partnerApplicationInfo),
        isLoading: isRequestPending(partnerApplicationInfo),
        isLoaded: isRequestSuccess(partnerApplicationInfo),
        isError: isRequestError(partnerApplicationInfo),
        errorMessage: getErrorMessage(partnerApplicationInfo),
        isUpdating: partnerApplicationInfo.updating,
      })
    );

  const { applicationId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded && applicationId === data._id) {
      dispatch(updatePartnerApplicationInfo({ applicationId }));
    } else {
      dispatch(getPartnerApplicationInfo({ applicationId }));
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <PartnerApplicationView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      isUpdating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
