import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";
import { getProjectSettingsData } from "../../actions/projectSettings";
import { updateProjectSettingsBots } from "../../actions/projectSettingsBots";
import { ProjectSettingsBotsView } from "./View";
import { PROJECT_SETTINGS } from "../../constants/fields";

export const ProjectSettingsBots = () => {
  const dispatch = useDispatch();

  const {
    data,
    isDataLoaded,
    isDataLoading,
    isLoading,
    isError,
    errorMessage,
  } = useSelector(
    ({
      projectSettings: { data: projectSettings },
      projectSettingsBots: { request: projectSettingsBots },
    }) => ({
      data: getData(projectSettings),
      isDataLoaded: isRequestSuccess(projectSettings),
      isDataLoading: isRequestPending(projectSettings),
      isLoading: isRequestPending(projectSettingsBots),
      isError: isRequestError(projectSettingsBots),
      errorMessage: getErrorMessage(projectSettingsBots),
    })
  );

  const handleApprove = async () => {
    await dispatch(
      updateProjectSettingsBots({
        [PROJECT_SETTINGS.BOTS_ENABLED]: true,
      })
    );
    dispatch(getProjectSettingsData());
  };

  const handleReject = async () => {
    await dispatch(
      updateProjectSettingsBots({
        [PROJECT_SETTINGS.BOTS_ENABLED]: false,
      })
    );
    dispatch(getProjectSettingsData());
  };

  const getLoading = () => {
    return !isDataLoaded || isDataLoading || isLoading;
  };

  return (
    <ProjectSettingsBotsView
      dataLoaded={isDataLoaded}
      loading={getLoading()}
      handleApprove={handleApprove}
      handleReject={handleReject}
      error={isError}
      errorMessage={errorMessage}
      isBotsEnabled={data.isBotsEnabled}
    />
  );
};
