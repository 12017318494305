import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { USER_FIND } from "../../constants/fields";
import { TextField } from "../../components/fields";
import { Box } from "../../components/cards";
import { ButtonPrimary } from "../../components/buttons";
import { IndentLayout } from "../../components/layouts";
import { FormTitle } from "../../components/titles";
import { Text, Loader } from "../../components";
import { FieldGrid } from "../../components/grids";

export const UserFindView = ({
  disabled,
  loading,
  findValue,
  handleChangeFindValue,
}) => (
  <React.Fragment>
    {loading && <Loader />}
    <Box variant="outlined">
      <IndentLayout>
        <FormTitle tid="USER.FIND_USER.FORM.TITLE" />
        <FieldGrid double button indent={false}>
          <TextField
            name={USER_FIND.FIND_VALUE}
            value={findValue}
            onChange={(e) => handleChangeFindValue(e.target.value)}
            label={<Text tid="USER.FIND_USER.FORM.FIND_VALUE" />}
          />
          <SubmitButton
            size="large"
            variant="outlined"
            color="primary"
            disabled={disabled}
            type="submit"
          >
            <Text tid="USER.FIND_USER.FORM.BUTTON_SUBMIT" />
          </SubmitButton>
        </FieldGrid>
      </IndentLayout>
    </Box>
  </React.Fragment>
);

const SubmitButton = styled(ButtonPrimary)`
  && {
    height: 56px;
  }
`;

UserFindView.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
