import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAGINATION_OFFSET } from "../../constants/routes";
import { getDepositList, updateDepositList } from "../../actions/depositList";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isUpdatePending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";
import { DEPOSIT_STATUS } from "../../constants/static";

import { DepositListView } from "./View";

export const DepositList = () => {
  const {
    isLoading,
    isLoaded,
    isError,
    errorMessage,
    depositsData,
    isUpdating,
  } = useSelector(({ depositList: { data } }) => ({
    isLoading: isRequestPending(data),
    isLoaded: isRequestSuccess(data),
    isUpdating: isUpdatePending(data),
    isError: isRequestError(data),
    errorMessage: getErrorMessage(data),
    depositsData: getData(data),
  }));

  const dispatch = useDispatch();

  const [currentStatus, setCurrentStatus] = useState(DEPOSIT_STATUS.PENDING);

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        updateDepositList({
          skip: 0,
          take: PAGINATION_OFFSET.DEPOSIT_LIST,
          status: currentStatus,
        })
      );
    } else {
      dispatch(
        getDepositList({
          skip: 0,
          take: PAGINATION_OFFSET.DEPOSIT_LIST,
          status: currentStatus,
        })
      );
    }

    // return () => {
    //   dispatch(resetStore());
    // };
  }, [currentStatus]);

  const paginationAction = () => {
    dispatch(
      updateDepositList({
        skip: depositsData.pagination.skip + PAGINATION_OFFSET.DEPOSIT_LIST,
        take: PAGINATION_OFFSET.DEPOSIT_LIST,
        status: currentStatus,
      })
    );
  };

  const handleChangeCurrentStatus = (e, status) => {
    setCurrentStatus(status);
  };

  const loading = !isLoaded || isLoading;

  return (
    <DepositListView
      loading={loading}
      loaded={isLoaded}
      updating={isUpdating}
      error={isError}
      errorMessage={errorMessage}
      deposits={depositsData}
      paginationAction={paginationAction}
      currentStatus={currentStatus}
      handleChangeCurrentStatus={handleChangeCurrentStatus}
    />
  );
};
