import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Header } from "../../containers/header";
import { fade, sizes, colors } from "../../theme";

export const PageLayout = ({ align = "top", children, indent = true }) => {
  return (
    <Page align={align} indent={indent}>
      <Header />
      {children}
    </Page>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
  indent: PropTypes.bool,
};

const Page = styled.div`
  animation: ${fade} 0.5s;
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: ${({ align }) =>
    align === "top" ? "flex-start" : align === "center" ? "center" : null};
  padding: 56px 0 ${({ indent }) => (indent ? sizes.indent.big : 0)};
`;
