/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";
import { StorageView } from "./View";

const def = {
  isDoubleHistoryToday: true,
  isDoubleHistory100: true,
  isCryptoCurrency: false,
  isClassicHistory: true,
  isClassicTop: true,
  isBots: true,
};

export const Storage = () => {
  const [viewSettings, setViewSettings] = useState(def);

  const [spacePressed, setSpacePressed] = useState(true);
  const [jobsStorage, setJobStorage] = useState({});
  const [timer, setTimer] = useState(null);

  const handleViewSettings = (v = def) => {
    setViewSettings((prev) => ({ ...prev, ...v }));
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
      setSpacePressed((prev) => !prev);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    if (timer) clearInterval(timer);

    if (!spacePressed) {
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }

    const intervalId = setInterval(async () => {
      try {
        const { data } = await axios.post(API.STORAGE(), viewSettings);
        setJobStorage(data);
      } catch (error) {
        console.error(error);
      }
    }, 1000);

    setTimer(intervalId);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearInterval(intervalId);
    };
  }, [spacePressed, viewSettings]);

  return (
    <StorageView
      jobsStorage={jobsStorage}
      spacePressed={spacePressed}
      viewSettings={viewSettings}
      handleViewSettings={handleViewSettings}
    />
  );
};
