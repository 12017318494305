import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login as loginAction } from "../../actions/login";
import { AUTH_USER } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
} from "../../utils/store";

import { LogInView } from "./View";

export const LogIn = () => {
  const { isLoading, isError, errorMessage } = useSelector(({ login }) => ({
    isLoading: isRequestPending(login.request),
    isError: isRequestError(login.request),
    errorMessage: getErrorMessage(login.request),
  }));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      loginAction(
        {
          [AUTH_USER.EMAIL]: fields.email,
          [AUTH_USER.PASSWORD]: fields.password,
        },
        navigate
      )
    );
  };

  const isFormDisabled = !fields.email || !fields.password || isLoading;

  return (
    <LogInView
      fields={fields}
      changeField={changeField}
      onSubmit={onSubmit}
      disabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
