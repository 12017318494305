/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "styled-components";
import { JSONTree } from "react-json-tree";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CLUSTERS = {
  DOUBLE_HISTORY_TODAY: "DOUBLE_HISTORY_TODAY",
  DOUBLE_HISTORY_100: "DOUBLE_HISTORY_100",
  CRYPTO_CURRENCIES: "CRYPTO_CURRENCIES",
  CLASSIC_HISTORY: "CLASSIC_HISTORY",
  CLASSIC_TOP: "CLASSIC_TOP",
  BOTS: "BOTS",
};

const collapsedClusters = [CLUSTERS.CRYPTO_CURRENCIES];

const Cluster = ({ __src, __title }) => {
  const { t } = useTranslation();
  const title = t(`STORAGE.TABLE_TITLES.${__title}`);
  return (
    <ClusterWrapper
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: 500,
        width: "100%",
      }}
    >
      <JobTitle style={{ fontSize: 14 }}>{title}</JobTitle>
      <JSONTree
        style={{ minHeight: "100%", borderRadius: 6, padding: 6 }}
        theme={{
          author: "wimer hazenberg (http://www.monokai.nl)",
          scheme: "monokai",
          base00: "#000000",
          base01: "#383830",
          base02: "#49483e",
          base03: "#75715e",
          base04: "#a59f85",
          base05: "#f8f8f2",
          base06: "#f5f4f1",
          base07: "#f9f8f5",
          base08: "#f92672",
          base09: "#fd971f",
          base0A: "#f4bf75",
          base0B: "#a6e22e",
          base0C: "#a1efe4",
          base0D: "#66d9ef",
          base0E: "#ae81ff",
          base0F: "#cc6633",
        }}
        data={__src}
        hideRoot
      />
    </ClusterWrapper>
  );
};

export const StorageView = ({
  jobsStorage,
  spacePressed,
  viewSettings,
  handleViewSettings,
}) => {
  const { t } = useTranslation();

  return (
    <JobsWrapper>
      <ViewSettings>
        <div>
          {spacePressed
            ? t("STORAGE.LIVE_UPDATES_ON")
            : t("STORAGE.LIVE_UPDATES_OFF")}
        </div>
        {Object.keys(viewSettings).map((field) => (
          <ViewSettingsItem>
            <Checkbox
              {...label}
              defaultChecked={viewSettings[field]}
              onChange={() =>
                handleViewSettings({ [field]: !viewSettings[field] })
              }
            />
            {field}
          </ViewSettingsItem>
        ))}
      </ViewSettings>
      {viewSettings.isDoubleHistoryToday ||
      viewSettings.isDoubleHistory100 ||
      viewSettings.isCryptoCurrency ? (
        <div>
          {viewSettings.isDoubleHistoryToday && (
            <Cluster
              __src={jobsStorage[CLUSTERS.DOUBLE_HISTORY_TODAY]}
              __title={CLUSTERS.DOUBLE_HISTORY_TODAY}
            />
          )}
          {viewSettings.isDoubleHistory100 && (
            <Cluster
              __src={jobsStorage[CLUSTERS.DOUBLE_HISTORY_100]}
              __title={CLUSTERS.DOUBLE_HISTORY_100}
            />
          )}
          {viewSettings.isCryptoCurrency && (
            <Cluster
              __src={jobsStorage[CLUSTERS.CRYPTO_CURRENCIES]}
              __title={CLUSTERS.CRYPTO_CURRENCIES}
            />
          )}
        </div>
      ) : null}
      <div>
        {viewSettings.isClassicHistory && (
          <Cluster
            __src={jobsStorage[CLUSTERS.CLASSIC_HISTORY]}
            __title={CLUSTERS.CLASSIC_HISTORY}
          />
        )}
        {viewSettings.isClassicTop && (
          <Cluster
            __src={jobsStorage[CLUSTERS.CLASSIC_TOP]}
            __title={CLUSTERS.CLASSIC_TOP}
          />
        )}
        {viewSettings.isBots && (
          <Cluster __src={jobsStorage[CLUSTERS.BOTS]} __title={CLUSTERS.BOTS} />
        )}
      </div>
    </JobsWrapper>
  );
};
/*  */
const ClusterWrapper = styled.div`
  & > ul {
    padding: 2px 6px !important;
    border-radius: 6px;
    min-height: 100%;
  }
`;

const ViewSettingsItem = styled.span`
  font-size: 14px !important;
`;

const ViewSettings = styled.div``;

const JobTitle = styled.div`
  font-size: 12px !important;
  font-weight: bold;
`;

const JobsWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  gap: 12px;

  *:not(:nth-child(1)):not(span) {
    font-size: 12px !important;
  }

  & > div:not(:nth-child(1)) {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    border-radius: 12px;
    min-height: 350px;
    display: flex;
    padding: 6px;
    width: 100%;
    gap: 6px;
  }

  & div {
    ::-webkit-scrollbar {
      display: none !important;
    }
  }

  & > div:not(:nth-child(1)) > div {
    height: 500px;
  }

  @media (max-width: 1300px) {
    & > div:not(:nth-child(1)) {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
    }

    & > div:not(:nth-child(1)) > div {
      height: 500px;
    }
  }
`;
