/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

export const HeaderView = ({ logged, ...props }) => {
  return (
    <React.Fragment>
      {logged && (
        <Container>
          <Desktop>
            <DesktopHeader logged={logged} {...props} />
          </Desktop>
          <Mobile>
            <MobileHeader logged={logged} {...props} />
          </Mobile>
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.div``;

const Mobile = styled.div`
  @media all and (min-width: 1200px) {
    display: none;
  }
`;

const Desktop = styled.div`
  @media all and (max-width: 1199px) {
    display: none;
  }
`;

HeaderView.propTypes = {
  logged: PropTypes.bool.isRequired,
};
