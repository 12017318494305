import { combineReducers } from "redux";

import { auth } from "./auth";
import { login } from "./login";
import { user } from "./user";
import userList from "./userList";
import staffList from "./staffList";
import userInfo from "./userInfo";
import userStatisticsInfo from "./userStatisticsInfo";
import userBlocking from "./userBlocking";
import userRoleChange from "./userRoleChange";
import partnerApplicationList from "./partnerApplicationList";
import partnerApplicationInfo from "./partnerApplicationInfo";
import partnerApplicationStatus from "./partnerApplicationStatus";
import withdrawList from "./withdrawList";
import withdrawInfo from "./withdrawInfo";
import withdrawStatus from "./withdrawStatus";
import depositList from "./depositList";
import depositInfo from "./depositInfo";
import statisticsProfit from "./statisticsProfit";
import statisticsUsersBalance from "./statisticsUsersBalance";
import statisticsBotsBalance from "./statisticsBotsBalance";
import projectSettings from "./projectSettings";
import projectSettingsBots from "./projectSettingsBots";
import statisticsUsersAddBalance from "./statisticsUsersAddBalance";

export default combineReducers({
  auth,
  login,
  user,
  userList,
  staffList,
  userInfo,
  userStatisticsInfo,
  userBlocking,
  userRoleChange,
  partnerApplicationList,
  partnerApplicationInfo,
  partnerApplicationStatus,
  withdrawList,
  withdrawInfo,
  withdrawStatus,
  depositList,
  depositInfo,
  statisticsProfit,
  statisticsUsersBalance,
  statisticsBotsBalance,
  projectSettings,
  projectSettingsBots,
  statisticsUsersAddBalance,
});
