import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getStatisticsProfit,
  updateStatisticsProfit,
} from "../../actions/statisticsProfit";
import { StatisticsProfitView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const StatisticsProfit = () => {
  const { data, isLoading, isLoaded, isError, errorMessage } = useSelector(
    ({ statisticsProfit: { data: statisticsProfit } }) => ({
      data: getData(statisticsProfit),
      isLoading: isRequestPending(statisticsProfit),
      isLoaded: isRequestSuccess(statisticsProfit),
      isError: isRequestError(statisticsProfit),
      errorMessage: getErrorMessage(statisticsProfit),
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(updateStatisticsProfit());
    } else {
      dispatch(getStatisticsProfit());
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <StatisticsProfitView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
