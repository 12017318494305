export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  USER_LIST: "/user",
  PARTNER_APPLICATION_LIST: "/partner-application",
  PARTNER_APPLICATION_INFO: (
    { applicationId } = { applicationId: ":applicationId" }
  ) => `/partner-application/${applicationId}`,
  USER_INFO: ({ userId } = { userId: ":userId" }) => `/user/${userId}`,
  STAFF_LIST: "/staff",
  WITHDRAW_LIST: "/withdraw",
  WITHDRAW_INFO: ({ withdrawId } = { withdrawId: ":withdrawId" }) =>
    `/withdraw/${withdrawId}`,
  DEPOSIT_LIST: "/deposit",
  DEPOSIT_INFO: ({ depositId } = { depositId: ":depositId" }) =>
    `/deposit/${depositId}`,
  STATISTICS: "/statistics",
  BALANCES: "/balances",
  PROJECT_SETTINGS: "/project-settings",
  JOBS_STORAGE: "/jobs-storage",
  STORAGE: "/storage",
};

export const PAGINATION_OFFSET = {
  USER_LIST: 20,
  STAFF_LIST: 20,
  DEPOSIT_LIST: 20,
  WITHDRAW_LIST: 20,
  REFERRAL_BONUS_LIST: 20,
  PARTNER_APPLICATION_LIST: 20,
};
export const AUTH_MENU = [
  { id: 1, tid: "NAVIGATION.PATH.USERS", path: ROUTES.USER_LIST },
  { id: 2, tid: "NAVIGATION.PATH.STAFF", path: ROUTES.STAFF_LIST },
  {
    id: 3,
    tid: "NAVIGATION.PATH.PARTNER_APPLICAITONS",
    path: ROUTES.PARTNER_APPLICATION_LIST,
  },
  { id: 4, tid: "NAVIGATION.PATH.DEPOSITS", path: ROUTES.DEPOSIT_LIST },
  { id: 5, tid: "NAVIGATION.PATH.WITHDRAWS", path: ROUTES.WITHDRAW_LIST },
  { id: 6, tid: "NAVIGATION.PATH.STATISTICS", path: ROUTES.STATISTICS },
  { id: 7, tid: "NAVIGATION.PATH.BALANCES", path: ROUTES.BALANCES },
  {
    id: 8,
    tid: "NAVIGATION.PATH.PROJECT_SETTINGS",
    path: ROUTES.PROJECT_SETTINGS,
  },
  {
    id: 9,
    tid: "NAVIGATION.PATH.JOBS_STORAGE",
    path: ROUTES.JOBS_STORAGE,
  },
  {
    id: 10,
    tid: "NAVIGATION.PATH.STORAGE",
    path: ROUTES.STORAGE,
  },
  // {
  //   id: 5,
  //   tid: "NAVIGATION.PATH.REFERRAL_BONUS",
  //   path: ROUTES.REFERRAL_BONUS_LIST,
  // },
];

export const USER_MENU = [
  {
    id: 1,
    tid: "USER.USER_MENU.INFO",
    path: ROUTES.USER_INFO,
  },
];
