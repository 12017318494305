import { axios } from "../utils/request";

import { API } from "../constants/api";
import { DEPOSIT_LIST } from "./index";
import { performDepositListData } from "../api/deposit";

const setFail = (message) => ({
  type: DEPOSIT_LIST.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: DEPOSIT_LIST.LOAD_SUCCESS,
  data: performDepositListData(data),
});

const setLoading = () => ({
  type: DEPOSIT_LIST.LOAD_PENDING,
});

const updateSuccess = (data) => ({
  type: DEPOSIT_LIST.UPDATE_SUCCESS,
  data: performDepositListData(data),
});

const updatePending = () => ({
  type: DEPOSIT_LIST.UPDATE_PENDING,
});

export const resetStore = () => ({
  type: DEPOSIT_LIST.RESET_STORE,
});

export const getDepositList = ({ skip, take, status: depositStatus }) => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const { data } = await axios.get(
        API.DEPOSIT_LIST({ skip, take, status: depositStatus })
      );
      dispatch(setSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};

export const updateDepositList = ({ skip, take, status: depositStatus }) => {
  return async (dispatch) => {
    dispatch(updatePending());
    try {
      const { data } = await axios.get(
        API.DEPOSIT_LIST({ skip, take, status: depositStatus })
      );
      dispatch(updateSuccess(data));
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
