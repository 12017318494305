import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const UserRoleCheck = ({ roles = [], children }) => {
  const { user } = useSelector(({ auth }) => auth);

  if (!user) {
    return null;
  }

  const { role: userRole } = user;

  const isRoleMatching = roles.some((role) => role === userRole);

  return <React.Fragment>{isRoleMatching ? children : null}</React.Fragment>;
};

UserRoleCheck.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.node,
};
