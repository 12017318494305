import { axios } from "../utils/request";

import { API } from "../constants/api";
import { USER_ROLE_CHANGE } from "./index";
import { convertChangeUserRoleData } from "../api/user";

const setFail = (message) => ({
  type: USER_ROLE_CHANGE.REQUEST_FAIL,
  message,
});

const setRequestSuccess = (data) => ({
  type: USER_ROLE_CHANGE.REQUEST_SUCCESS,
  data,
});

const setRequestLoading = () => ({
  type: USER_ROLE_CHANGE.REQUEST_PENDING,
});

export const resetUserRoleChangeStatus = () => ({
  type: USER_ROLE_CHANGE.RESET_STATUS,
});

export const changeUserRole = ({ userId }, payloadData) => {
  return async (dispatch) => {
    const payload = convertChangeUserRoleData(payloadData);

    dispatch(setRequestLoading());

    try {
      const { data } = await axios.patch(API.USER_ROLE({ userId }), payload);
      dispatch(setRequestSuccess());
      return data;
    } catch ({ response: { data, status } }) {
      dispatch(setFail(data.message));
    }
  };
};
