import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getStatisticsUsersBalance,
  updateStatisticsUsersBalance,
} from "../../actions/statisticsUsersBalance";
import { StatisticsUsersBalanceView } from "./View";
import {
  getData,
  isRequestSuccess,
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";

export const StatisticsUsersBalance = () => {
  const { data, isLoading, isLoaded, isError, errorMessage } = useSelector(
    ({ statisticsUsersBalance: { data: statisticsUsersBalance } }) => ({
      data: getData(statisticsUsersBalance),
      isLoading: isRequestPending(statisticsUsersBalance),
      isLoaded: isRequestSuccess(statisticsUsersBalance),
      isError: isRequestError(statisticsUsersBalance),
      errorMessage: getErrorMessage(statisticsUsersBalance),
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      dispatch(updateStatisticsUsersBalance());
    } else {
      dispatch(getStatisticsUsersBalance());
    }
  }, []);

  const getLoading = () => {
    return !isLoaded || isLoading;
  };

  return (
    <StatisticsUsersBalanceView
      data={data}
      loaded={isLoaded}
      loading={getLoading()}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
