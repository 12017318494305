import React from "react";

import MuiTabs from "@mui/material/Tabs";

export const Tabs = ({ ...props }) => {
  return (
    <MuiTabs
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      {...props}
    />
  );
};
