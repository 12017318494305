import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { spacing, colors } from '../../theme';
import { Box } from '../../components/cards';
import { IndentLayout } from '../../components/layouts';
import { Loader, AlertSection, Text } from '../../components';
import { FormTitle } from '../../components/titles';
import { ButtonDanger } from '../../components/buttons';
import { SkeletonList } from '../../components/skeletons';

export const UserBlockingView = ({
  blocked,
  dataLoading,
  dataLoaded,
  requestLoading,
  requestLoaded,
  disabled,
  error,
  errorMessage,
}) => {
  return (
    <React.Fragment>
      <Box variant="outlined">
        <IndentLayout>
          <FormTitle tid="USER.BLOCK_USER.TITLE" offset={3} />
          {requestLoading && <Loader />}
          {dataLoading && (
            <React.Fragment>
              <Loader />
              <SkeletonList />
            </React.Fragment>
          )}
          {dataLoaded && (
            <React.Fragment>
              <BlockStatus>
                <Text tid={`USER.BLOCK_USER.USER_${blocked ? 'BLOCKED' : 'NOT_BLOCKED'}`} />
              </BlockStatus>
              <ButtonDanger size="large" variant="outlined" disabled={disabled} type="submit">
                <Text tid={`USER.BLOCK_USER.BUTTON_${blocked ? 'UNBLOCK' : 'BLOCK'}`} />
              </ButtonDanger>
            </React.Fragment>
          )}
        </IndentLayout>
      </Box>

      <AlertSection
        success={requestLoaded}
        successMessage={`USER.BLOCK_USER.SUCCESS_${blocked ? 'BLOCK' : 'UNBLOCK'}`}
        error={error}
        errorMessage={errorMessage}
      />
    </React.Fragment>
  );
};

UserBlockingView.propTypes = {
  blocked: PropTypes.bool,
  dataLoading: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  requestLoading: PropTypes.bool,
  requestLoaded: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

const BlockStatus = styled.p`
  font-size: 16px;
  color: ${colors.textPrimary};
  margin-bottom: ${spacing(4)};
`;
